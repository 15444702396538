import { useCallback, useState } from "react";

interface UseAutoplayModal {
  isAutoplayModalToggle: boolean;
  toggleAutoplayModal: (toggled: boolean) => void;
  playAudioElementCallback?: () => void;
}

const useAutoplayModal = (): UseAutoplayModal => {
  const [isAutoplayModalToggle, setIsAutoplayModalToggle] = useState(false);

  const playAudioElementCallback = useCallback(() => {
    const allAudioElements = [...document.getElementsByTagName("audio")];

    Promise.all(allAudioElements.map((a) => a.play()));
  }, []);

  const toggleAutoplayModal = useCallback(
    (isToggle: boolean) => {
      setIsAutoplayModalToggle(isToggle);
    },
    [setIsAutoplayModalToggle]
  );

  return {
    isAutoplayModalToggle,
    toggleAutoplayModal,
    playAudioElementCallback
  };
};
export default useAutoplayModal;
