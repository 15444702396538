import { useEffect, useState } from "react";
import { AudioTrack, VideoTrack } from "twilio-video";
import { MEDIA_STREAM_STATE } from "../../constants/events";

export default function useMediaStreamTrack(
  track?: AudioTrack | VideoTrack
): MediaStreamTrack | undefined {
  const [mediaStreamTrack, setMediaStreamTrack] = useState(
    track?.mediaStreamTrack
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { STARTED } = MEDIA_STREAM_STATE;
    setMediaStreamTrack(track?.mediaStreamTrack);

    if (track) {
      const handleStarted = (): void =>
        setMediaStreamTrack(track.mediaStreamTrack);
      track.on(STARTED, handleStarted);
      return (): void => {
        track.off(STARTED, handleStarted);
      };
    }
  }, [track]);

  return mediaStreamTrack;
}
