import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { SettingsModal, TwilioRoom } from "..";
import { HOME } from "../../constants/routes";
import { useMeetingContext } from "../../hooks";
import { useCurrentMeetingId } from "../../hooks/useCurrentMeetingId";
import { UtilService } from "../../services";
import TwilioLobby from "../lobby/TwilioLobby/TwilioLobby";

export default function TwilioMeetingHolder() {
  const { replace } = useHistory();
  const { twilioToken } = useMeetingContext();
  const meetingId = useCurrentMeetingId();

  useEffect(() => {
    if (!UtilService.isValidMeetingId(meetingId)) {
      replace(HOME);
    }
  }, [meetingId, replace]);

  return (
    <>
      <SettingsModal />
      {twilioToken ? <TwilioRoom /> : <TwilioLobby />}
    </>
  );
}
