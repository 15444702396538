import i18next from "i18next";
import { matchPath } from "react-router-dom";
import { Observable } from "rxjs";

import { MEETING_API, PUSHER_API } from "../constants/api";
import { MEETING } from "../constants/routes";
import { ApiSuccessResponse } from "../models/api";
import {
  DialInApiRequest,
  DialInApiResponse,
  ExternalJoinStateUpdateRequest,
  MeetingGetResponse,
  MeetingRequest,
  PusherAuthenticateRequest,
  PusherAuthenticateResponse,
  RecordMeetingRequest,
  RecordMeetingSuccess,
  TimezoneResponse,
  UpdateDialInStatusRequest,
  UpdateDialInStatusResponse
} from "../models/meeting";
import { DialInStatus } from "../models/view/participants";
import { LegacyRestService } from "./LegacyRestService";

export const LegacyMeetingService = {
  // TODO: twilioToken is returned from this endpoint
  getMeeting: ({
    name,
    email,
    meetingId,
    recaptchaToken,
    browserAgent
  }: MeetingRequest): Observable<MeetingGetResponse> =>
    LegacyRestService.post<MeetingGetResponse>(
      MEETING_API.GET_MEETING(meetingId),
      {
        name,
        email,
        recaptchaToken,
        browserAgent
      }
    ),

  getMeetingIdFromURL: (): string | undefined => {
    const path = matchPath<{ meetingId: string }>(
      window.location.pathname,
      MEETING
    );

    return path?.params.meetingId;
  },

  getTimezones: (): Observable<TimezoneResponse> =>
    LegacyRestService.get<TimezoneResponse>(MEETING_API.GET_TIMEZONES()),

  pusherAuthenticate: (
    pusherAuthenticateRequest: PusherAuthenticateRequest
  ): Observable<PusherAuthenticateResponse> =>
    LegacyRestService.post<PusherAuthenticateResponse>(
      PUSHER_API.PUSHER_AUTHENTICATE(),
      pusherAuthenticateRequest
    ),

  getExternalParticipants: (
    meetingId: string,
    joinRequestId: string
  ): Observable<MeetingGetResponse> =>
    LegacyRestService.post<MeetingGetResponse>(
      MEETING_API.GET_EXTERNAL_PARTICIPANTS(meetingId),
      {
        joinRequestId
      }
    ),

  joinRequestStateUpdate: ({
    meetingId,
    approval,
    joinRequestId,
    twilioToken
  }: ExternalJoinStateUpdateRequest): Observable<ApiSuccessResponse> =>
    LegacyRestService.put(
      MEETING_API.UPDATE_EXTERNAL_PARTICIPANT_STATE(meetingId),
      {
        approval,
        joinRequestId,
        twilioToken
      }
    ),

  dialInParticipant: ({
    meetingId,
    callToNumber,
    twilioToken
  }: DialInApiRequest): Observable<DialInApiResponse> =>
    LegacyRestService.post(MEETING_API.DIAL_IN_PARTICIPANT(meetingId), {
      callToNumber,
      twilioToken
    }),

  updateDialInStatus: ({
    meetingId,
    callSid,
    twilioToken
  }: UpdateDialInStatusRequest): Observable<UpdateDialInStatusResponse> =>
    LegacyRestService.post(
      MEETING_API.UPDATE_DIAL_IN_PARTICIPANT_STATUS(meetingId),
      {
        callSid,
        twilioToken
      }
    ),

  recordMeeting: ({
    isRecording,
    pathRoomSid,
    participantEmail
  }: RecordMeetingRequest): Observable<RecordMeetingSuccess> =>
    LegacyRestService.post(MEETING_API.RECORD_MEETING(), {
      isRecording,
      pathRoomSid,
      participantEmail
    }),

  viewDialInStatus: (status: DialInStatus): string | null => {
    const { INITIATED, RINGING, QUEUED, IN_PROGRESS, BUSY, NO_ANSWER, FAILED } =
      DialInStatus;
    switch (status) {
      case QUEUED:
      case INITIATED:
        return i18next.t("room:calling");
      case RINGING:
      case IN_PROGRESS:
        return i18next.t("room:ringing");
      case BUSY:
        return i18next.t("room:busy");
      case NO_ANSWER:
        return i18next.t("room:noAnswer");
      case FAILED:
        return i18next.t("room:unreachable");
      default:
        return null;
    }
  }
};
