import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import { CIQSelect } from "../..";
import {
  useIsTrackEnabled,
  useMediaStreamTrack,
  useMeetingContext,
  useModalContext,
  useVideoInputDevices
} from "../../../hooks";
import VideoPreview from "../../videoPreview/VideoPreview";

export function VideoInputDevices(): JSX.Element {
  const { t } = useTranslation("settingsModal");
  const { localVideoTrack } = useMeetingContext();

  const { setSelectedVideoDevice, selectedVideoDeviceId } = useModalContext();
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const isVideoTrackEnabled = useIsTrackEnabled(localVideoTrack);
  const videoInputDevices = useVideoInputDevices();
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  const hasDevices = videoInputDevices.length > 0;

  const selectedDeviceOption = videoInputDevices.find(
    (d) => d.value === (localVideoInputDeviceId || selectedVideoDeviceId)
  );

  function replaceTrack(newDeviceId: string): void {
    localVideoTrack?.restart({
      deviceId: { exact: newDeviceId }
    });
    setSelectedVideoDevice(newDeviceId);
  }

  return (
    <div className="video-input-list">
      <Label className="space-between">
        {t("camera")}&nbsp;
        <span>{!isVideoTrackEnabled && t("cameraOff")}</span>
      </Label>
      {hasDevices ? (
        <>
          <CIQSelect
            defaultValue={selectedDeviceOption}
            options={videoInputDevices}
            onSelectChange={(value: string): void => replaceTrack(value)}
            noOptionsMessage={t("noOptions")}
            placeholder={t("selectVideoInput")}
            customStyle
          />
          {isVideoTrackEnabled && (
            <VideoPreview track={localVideoTrack!} isLocalParticipant />
          )}
        </>
      ) : (
        <p>{t("noVideoDevices")}</p>
      )}
    </div>
  );
}
