import React from "react";

import { EventService } from "../../services/EventService";

import { DocumentType, extensionToIconMap } from "../../constants/events";

export function generateFileIcon(fileName: string): JSX.Element {
  const extension = EventService.getFileExtension(fileName);
  const icon = extensionToIconMap.get(extension);

  switch (icon) {
    case DocumentType.PDF:
      return (
        <i className="ciq-pdf">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
        </i>
      );
    case DocumentType.XLS:
      return (
        <i className="ciq-xls">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
        </i>
      );

    case DocumentType.CSV:
      return (
        <i className="ciq-icon ciq-csv">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
        </i>
      );

    case DocumentType.PRESENTATION:
      return (
        <i className="ciq-presentation">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
        </i>
      );

    case DocumentType.DOC:
      return (
        <i className="ciq-doc-word">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
        </i>
      );

    case DocumentType.ZIP:
      return (
        <i className="ciq-zip">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
        </i>
      );

    case DocumentType.EXCEL:
      return (
        <i className="ciq-excel">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </i>
      );

    case DocumentType.IMAGE:
      return (
        <i className="ciq-image">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
        </i>
      );

    case DocumentType.EVENT:
      return (
        <i className="ciq-docs-calendar">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </i>
      );

    default:
      return (
        <i className="ciq-doc-generic">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </i>
      );
  }
}
