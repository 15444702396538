import clsx from "clsx";
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Subscription } from "rxjs";
import { CIQAvatar } from "../..";
import { CALL_STATUS_CHECK_INTERVAL } from "../../../constants/timer";
import { useMeetingContext } from "../../../hooks";
import { usePrevious } from "../../../hooks/usePrevious/UsePrevious";
import { ErrorResponse } from "../../../models/api";
import {
  DialInStatus,
  NotJoinedParticipant
} from "../../../models/view/participants";
import { LegacyMeetingService, UtilService } from "../../../services";
import {
  CALL_STATUS_CHECK,
  CALL_STATUS_CHECK_FAILURE,
  CALL_STATUS_CHECK_SUCCESS,
  DIAL_IN_PARTICIPANT,
  DIAL_IN_PARTICIPANT_FAILURE,
  DIAL_IN_PARTICIPANT_SUCCESS,
  notJoinedReducer
} from "./NotJoinedReducer";

export interface NotJoinedState {
  dialInStatus: DialInStatus;
  loading?: boolean;
  dialInError?: ErrorResponse;
}

const initialState = {
  dialInStatus: DialInStatus.IDLE,
  loading: false,
  dialInError: undefined
} as NotJoinedState;
interface NotJoinedParticipantItemProps {
  notJoinedParticipant: NotJoinedParticipant;
  onCallEnd: (dialedInParticipant: NotJoinedParticipant) => void;
}

const NotJoinedParticipantItem: React.FC<NotJoinedParticipantItemProps> = ({
  notJoinedParticipant,
  onCallEnd
}: NotJoinedParticipantItemProps): JSX.Element => {
  const meetingId = LegacyMeetingService.getMeetingIdFromURL();

  const [notJoinedState, dispatch] = useReducer(notJoinedReducer, initialState);
  const { dialInStatus, loading, dialInError } = notJoinedState;

  const { twilioToken } = useMeetingContext();
  const { t } = useTranslation("twilioRoom");

  const { phoneNumber, fromTab, participant } = notJoinedParticipant;
  const prevFromTab = usePrevious(fromTab);

  const callSid = useRef<string>();
  const isEndState = useRef<boolean>(false);
  const dialInParticipantSubscription = useRef(new Subscription());
  const updateDialInParticipantSubscription = useRef(new Subscription());
  const dialInStatusTimeInterval = useRef<any>();

  const isIdle = dialInStatus === DialInStatus.IDLE;
  const username =
    participant?.expertFirstName ||
    participant?.email ||
    UtilService.formatPhoneNumber(participant?.phoneNumber!);

  const callStatusCheck = useCallback((): void => {
    dialInStatusTimeInterval.current = setInterval(() => {
      if (isEndState.current) {
        clearInterval(dialInStatusTimeInterval.current!);
        dispatch({
          type: CALL_STATUS_CHECK
        });
        onCallEnd(notJoinedParticipant);
        return;
      }

      if (callSid.current) {
        updateDialInParticipantSubscription.current =
          LegacyMeetingService.updateDialInStatus({
            meetingId: meetingId!,
            callSid: callSid.current,
            twilioToken: twilioToken!
          }).subscribe(
            ({ callStatus }) => {
              const { COMPLETED, BUSY, FAILED, NO_ANSWER, CANCELED, IDLE } =
                DialInStatus;

              if (
                callStatus === BUSY ||
                callStatus === FAILED ||
                callStatus === NO_ANSWER
              ) {
                // Exit after another interval
                dispatch({
                  type: CALL_STATUS_CHECK_SUCCESS,
                  payload: {
                    dialInStatus: callStatus
                  }
                });
                isEndState.current = true;
              } else if (callStatus === COMPLETED || callStatus === CANCELED) {
                // Immediate call status exit
                clearInterval(dialInStatusTimeInterval.current!);
                dispatch({
                  type: CALL_STATUS_CHECK_SUCCESS,
                  payload: {
                    dialInStatus: IDLE
                  }
                });
                onCallEnd(notJoinedParticipant);
              } else {
                dispatch({
                  type: CALL_STATUS_CHECK_SUCCESS,
                  payload: {
                    dialInStatus: callStatus
                  }
                });
              }
            },
            (error: ErrorResponse) => {
              dispatch({
                type: CALL_STATUS_CHECK_FAILURE,
                payload: {
                  error
                }
              });

              clearInterval(dialInStatusTimeInterval.current!);
            }
          );
      }
    }, CALL_STATUS_CHECK_INTERVAL);
  }, [meetingId, notJoinedParticipant, onCallEnd, twilioToken]);

  const handleDialInParticipant = useCallback(
    (dialInNumber: string): void => {
      dispatch({
        type: DIAL_IN_PARTICIPANT
      });
      isEndState.current = false;

      dialInParticipantSubscription.current =
        LegacyMeetingService.dialInParticipant({
          callToNumber: dialInNumber,
          meetingId: meetingId!,
          twilioToken: twilioToken!
        }).subscribe(
          ({ sid }) => {
            callSid.current = sid;
            callStatusCheck();
            dispatch({
              type: DIAL_IN_PARTICIPANT_SUCCESS
            });
          },
          (error: ErrorResponse) => {
            dispatch({
              type: DIAL_IN_PARTICIPANT_FAILURE,
              payload: {
                error
              }
            });
          }
        );
    },
    [meetingId, twilioToken, callStatusCheck]
  );

  useEffect(() => {
    if (!prevFromTab && fromTab) {
      handleDialInParticipant(phoneNumber);
    }
  }, [fromTab, handleDialInParticipant, phoneNumber, prevFromTab]);

  useEffect(
    () => (): void => {
      dialInParticipantSubscription.current.unsubscribe();
      updateDialInParticipantSubscription.current.unsubscribe();

      if (dialInStatusTimeInterval.current)
        clearInterval(dialInStatusTimeInterval.current);
    },
    []
  );

  return (
    <div className="participant-item">
      <div className="left-container">
        <div className="users-avatar">
          <CIQAvatar
            isDialInParticipant
            username={username}
            isCalling
            profileImageUrl={participant?.profileImageUrl}
          />
        </div>
        <div className="user-name">{username}</div>
      </div>
      <div className="right-container">
        {isIdle ? (
          <p
            className={clsx("dial-in-text", { disabled: loading })}
            onClick={(): void =>
              handleDialInParticipant(participant?.phoneNumber!)
            }
          >
            {t("dialIn")}
          </p>
        ) : (
          <div className="calling-icon" />
        )}
      </div>
      {(!isIdle || dialInError) && (
        <div
          className={clsx("dial-in-status", {
            error: dialInError
          })}
        >
          {dialInError?.message ||
            LegacyMeetingService.viewDialInStatus(dialInStatus)}
        </div>
      )}
    </div>
  );
};

export default NotJoinedParticipantItem;
