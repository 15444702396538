import { useContext } from "react";
import { ModalContext, ModalContextState } from "../../contexts/ModalContext";

export default function useModalContext(): ModalContextState {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      "useModalContext must be used within a ModalContextProvider"
    );
  }
  return context;
}
