import { useMediaQuery } from "react-responsive";

import { useMeetingContext, useScreenShareParticipant } from "..";
import { STACK_MODE } from "../../constants/meeting";
import { APP_DEVICE_MEDIA_QUERIES } from "../../constants/ui";

export default function useStackMode(): STACK_MODE {
  const screenShareParticipant = useScreenShareParticipant();
  const { isEventSidebarOpen, isParticipantSidebarOpen } = useMeetingContext();

  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });
  const isPortrait = useMediaQuery({ orientation: "portrait" });

  const { GRID, HORIZONTAL, VERTICAL } = STACK_MODE;
  const isSidePanelOpen = isEventSidebarOpen || isParticipantSidebarOpen;

  let roomStackMode = GRID;

  if (screenShareParticipant) {
    roomStackMode = isSidePanelOpen ? HORIZONTAL : VERTICAL;

    if (!isLargeDevice) {
      roomStackMode = isPortrait ? HORIZONTAL : VERTICAL;
    }
  }

  return roomStackMode;
}
