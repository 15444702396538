import React, { useState } from "react";
import {
  isChrome,
  isEdgeChromium,
  isFirefox,
  isMobile
} from "react-device-detect";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane
} from "reactstrap";

import clsx from "clsx";
import { useModalContext } from "../../hooks";
import { AudioInputDevices } from "./audioInput/AudioInputDevices";
import { AudioOutputDevices } from "./audioOutput/AudioOutputDevices";
import { VideoInputDevices } from "./videoInput/VideoInputDevices";

enum SETTINGS_TABS {
  AUDIO,
  VIDEO
}

const isCustomSpeakerSupport =
  !isMobile && (isChrome || isEdgeChromium || isFirefox);

const SettingsModal = (): JSX.Element => {
  const { t } = useTranslation("settingsModal");
  const { isSettingsModalToggle, toggleSettingsModal } = useModalContext();
  const [activeTab, setActiveTab] = useState(SETTINGS_TABS.AUDIO);

  const modalPortal = document.getElementById("modal-portal");

  return createPortal(
    <Modal
      isOpen={isSettingsModalToggle}
      toggle={(): void => toggleSettingsModal(!isSettingsModalToggle)}
      className="settings-modal"
    >
      <ModalHeader>
        {t("settings")}
        <div
          className="btn-close"
          onClick={(): void => toggleSettingsModal(false)}
        >
          <i className="ciq-icon ciq-close" />
        </div>
      </ModalHeader>
      <ModalBody className="settings-modal-body">
        <div className="top-container">
          <div className="tab-button-container">
            <ButtonGroup>
              <Button
                className={clsx({
                  active: activeTab === SETTINGS_TABS.AUDIO
                })}
                type="button"
                color="default"
                size="sm"
                onClick={(): void => setActiveTab(SETTINGS_TABS.AUDIO)}
              >
                {t("audio")}
              </Button>
              <Button
                className={clsx({
                  active: activeTab === SETTINGS_TABS.VIDEO
                })}
                type="button"
                color="default"
                size="sm"
                onClick={(): void => setActiveTab(SETTINGS_TABS.VIDEO)}
              >
                {t("video")}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className="middle-container">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={SETTINGS_TABS.AUDIO}>
              <AudioInputDevices />
              {isCustomSpeakerSupport && <AudioOutputDevices />}
            </TabPane>
            <TabPane tabId={SETTINGS_TABS.VIDEO}>
              <VideoInputDevices />
            </TabPane>
          </TabContent>
        </div>
      </ModalBody>
    </Modal>,
    modalPortal!
  );
};

export default SettingsModal;
