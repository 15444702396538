import ZoomVideo, {
  ActiveSpeaker,
  ConnectionChangePayload,
  ConnectionState,
  Participant,
  PassiveStopShareReason,
  Stream,
  VideoClient,
  VideoPlayer,
  VideoQuality
} from "@zoom/videosdk";
import i18next from "i18next";
import {
  PEER_SCREEN_SHARE_CANVAS,
  SELF_SCREEN_SHARE_CANVAS,
  SELF_SCREEN_SHARE_VIDEO
} from "../../components/zoomRoom/ScreenShare";

import { PreviewTarget } from "../../constants/enums";
import { ZoomEvents } from "../../constants/zoomEvents";
import { debug } from "../../utils/logging";
import NotificationService from "../NotificationService";
import {
  MeetingParticipants,
  UPDATED_PARTICIPANTS_EVENT
} from "./MeetingParticipants";
import { MeetingVideo } from "./MeetingVideo";

export class ZoomMeeting {
  private client: typeof VideoClient;
  private stream: typeof Stream | undefined;

  private participants: MeetingParticipants;
  private video: MeetingVideo;

  private eventStopper = new AbortController();

  constructor() {
    this.client = ZoomVideo.createClient();
    this.client.on(ZoomEvents.ConnectionChange, (payload) => {
      debug("Connection change", payload);
      ZoomMeeting.onConnectionChange(payload);
    });
    this.client.on(ZoomEvents.DialoutStateChange, (payload) => {
      debug("Dial-out state change", payload);
    });

    this.participants = new MeetingParticipants(this.client);
    this.video = new MeetingVideo(this.client);
  }

  static onConnectionChange(payload: ConnectionChangePayload) {
    if (payload.state === ConnectionState.Closed) {
      const { reason } = payload;
      debug("Session ended", { reason });
      // cspell:disable-next-line
      if (reason === "kicked by host" || reason === "expeled by host") {
        NotificationService.showInfo(i18next.t("connectionChange:kickedOut"));
      } else if (reason === "ended by host") {
        NotificationService.showInfo(i18next.t("connectionChange:endedByHost"));
      }
      window.location.replace("/");
    } else if (payload.state === ConnectionState.Reconnecting) {
      debug("The client side has lost connection with the server", {
        reason: payload.reason
      });
      NotificationService.showInfo(i18next.t("connectionChange:reconnecting"));
    } else if (payload.state === ConnectionState.Connected) {
      debug("Connected to the session");
    } else if (payload.state === ConnectionState.Fail) {
      debug("Failed to reconnect OR user flushed from session", {
        reason: payload.reason
      });
    }
  }

  registerParticipantsCallback(
    callback: (participants: Participant[]) => void
  ) {
    this.participants.addEventListener(
      UPDATED_PARTICIPANTS_EVENT,
      (event) => callback((event as CustomEvent).detail.participants),
      { signal: this.eventStopper.signal }
    );
  }

  /**
   * The session begins when the first user joins.
   * The session name must match the tpc in the Video SDK JWT.
   * The host of the session is the user with a role set to 1 in the Video SDK JWT.
   * The sessionPasscode is optional, but if set for a session, it's required for other users joining that session.
   */
  async join(
    token: string,
    joinerName: string,
    sessionName: string,
    isVideoPreviewStopped: boolean,
    isSelfMuted: boolean,
    sessionPasscode?: string
  ) {
    await this.client.init("en-US", "Global", {
      patchJsMedia: true,
      enforceMultipleVideos: true,
      leaveOnPageUnload: true
    });
    await this.client.join(sessionName, token, joinerName, sessionPasscode);

    const stream = this.client.getMediaStream();
    this.stream = stream;
    if (this.stream === undefined) throw new Error("No stream!");

    await this.startAudio();

    // Microphone
    if (isSelfMuted) {
      await this.mute();
    } else {
      await this.unmute();
    }

    await this.video.join(stream, isVideoPreviewStopped);

    this.participants.join();
  }

  async leave() {
    this.participants.leave();
    this.eventStopper.abort();
    this.stopSharingScreen();
    await this.video.leave();
    await this.stream?.stopAudio();
    await this.client.leave();
    delete this.stream;
  }

  leaveWithoutCleanup() {
    this.client.leave();
  }

  // Participant management

  getCurrentUserInfo() {
    return this.client.getCurrentUserInfo();
  }

  async removeUser(userId: number) {
    await this.client.removeUser(userId);
  }

  // Video methods

  async startVideoPreview(deviceId: string, target: PreviewTarget) {
    await this.video.startVideoPreview(deviceId, target);
  }

  async stopVideoPreview() {
    await this.video.stopVideoPreview();
  }

  async startSelfVideo() {
    await this.video.startSelfVideo();
  }

  async stopSelfVideo() {
    await this.video.stopSelfVideo();
  }

  async attachVideo(userId: number, videoPlayer: VideoPlayer) {
    await this.stream!.attachVideo(
      userId,
      VideoQuality.Video_360P,
      videoPlayer
    );
  }

  async detachVideo(userId: number) {
    await this.stream!.detachVideo(userId);
  }

  // Video: camera

  getCameraList(): Promise<MediaDeviceInfo[]> {
    return this.video.getCameraList();
  }

  getCameraId() {
    // Normally you would use a getter e.g. "get cameraId() { ... }" but we need to expose this to React
    // Because it's a function, we can get the current value any time - a regular variable would be stale
    // That's because React can't listen to updates of a value like this easily
    return this.video.cameraId;
  }

  async switchCameraInput(deviceId: string) {
    debug("Switching camera input to", deviceId);
    await this.video.switchCameraInput(deviceId);
  }

  // Audio: mic

  private preselectedMicrophone: string | undefined;

  async getMicList() {
    const devices = await ZoomVideo.getDevices();
    const audioInputs = devices.filter(
      (device) => device.kind === "audioinput"
    );
    return audioInputs;
  }

  async switchMicrophone(microphoneId: string) {
    if (this.stream) {
      await this.stream?.switchMicrophone(microphoneId);
    } else {
      this.preselectedMicrophone = microphoneId;
    }
  }

  // Audio: speaker

  private preselectedSpeaker: string | undefined;

  async getSpeakerList() {
    const devices = await ZoomVideo.getDevices();
    const audioOutputs = devices.filter(
      (device) => device.kind === "audiooutput"
    );
    return audioOutputs;
  }

  async switchSpeaker(speakerId: string) {
    if (this.stream) {
      await this.stream?.switchSpeaker(speakerId);
    } else {
      this.preselectedSpeaker = speakerId;
    }
  }

  // Active speaker (participant)

  registerOnActiveSpeaker(callback: (payload: ActiveSpeaker[]) => void) {
    this.client.on(ZoomEvents.ActiveSpeaker, callback);
  }

  unRegisterOnActiveSpeaker(callback: (payload: ActiveSpeaker[]) => void) {
    this.client.off(ZoomEvents.ActiveSpeaker, callback);
  }

  // Screen sharing

  registerOnPassivelyStopShare(
    callback: (payload: PassiveStopShareReason) => void
  ) {
    this.client.on(ZoomEvents.PassivelyStopShare, callback);
  }

  unRegisterOnPassivelyStopShare(
    callback: (payload: PassiveStopShareReason) => void
  ) {
    this.client.off(ZoomEvents.PassivelyStopShare, callback);
  }

  registerOnActiveShareChange(
    callback: (payload: {
      state: "Active" | "Inactive";
      userId: number;
    }) => void
  ) {
    this.client.on(ZoomEvents.ActiveShareChange, callback);
  }

  unRegisterOnActiveShareChange(
    callback: (payload: {
      state: "Active" | "Inactive";
      userId: number;
    }) => void
  ) {
    this.client.off(ZoomEvents.ActiveShareChange, callback);
  }

  shareMyScreen() {
    if (!this.stream) throw new Error("Stream not initialized!");

    const screenShareElement = this.stream.isStartShareScreenWithVideoElement()
      ? document.getElementById(SELF_SCREEN_SHARE_VIDEO)
      : document.getElementById(SELF_SCREEN_SHARE_CANVAS);
    if (!screenShareElement) {
      throw new Error("Screen share HTML element not found!");
    }

    return this.stream.startShareScreen(
      screenShareElement as HTMLVideoElement | HTMLCanvasElement
    );
  }

  stopSharingScreen() {
    if (!this.stream) throw new Error("Stream not initialized!");
    return this.stream.stopShareScreen();
  }

  startShareView(userId: number) {
    return this.stream!.startShareView(
      document.getElementById(PEER_SCREEN_SHARE_CANVAS) as HTMLCanvasElement,
      userId
    );
  }

  stopShareView() {
    return this.stream!.stopShareView();
  }

  getSharingScreenUser() {
    const users = this.client.getAllUser();
    const userSharingScreen = users.find((user) => user.sharerOn);
    return {
      isSomeoneSharing: !!userSharingScreen,
      activeShareUserId: userSharingScreen?.userId
    };
  }

  // Audio methods

  private async startAudio() {
    await this.stream?.startAudio({
      microphoneId: this.preselectedMicrophone,
      speakerId: this.preselectedSpeaker,
      autoStartAudioInSafari: true
    });
  }

  async mute() {
    await this.stream?.muteAudio();
  }

  async unmute() {
    await this.stream?.unmuteAudio();
  }

  async setSpeakerVolume(volume: number) {
    const userIds = this.participants.participants.map(
      (participant) => participant.userId
    );
    await Promise.all(
      userIds.map((userId) =>
        this.stream?.adjustUserAudioVolumeLocally(userId, volume)
      )
    );
  }

  // Phone methods

  supportsPhoneFeature() {
    return this.stream?.isSupportPhoneFeature();
  }

  getCurrentSessionCallinInfo() {
    return this.stream?.getCurrentSessionCallinInfo();
  }

  async makeCall(countryCode: string, phoneNumber: string, name: string) {
    await this.stream?.inviteByPhone(countryCode, phoneNumber, name);
  }
}
