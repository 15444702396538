export interface GridVideoDimensionReducerState {
  colSize: number;
  rowCount: number;
}

export const SET_GRID_VIDEO_DIMENSIONS = "SET_GRID_VIDEO_DIMENSIONS";

interface SetGridDimensionActionType {
  type: typeof SET_GRID_VIDEO_DIMENSIONS;
  payload: {
    colSize: number;
    rowCount: number;
  };
}

export function gridVideoDimensionReducer(
  state: GridVideoDimensionReducerState,
  action: SetGridDimensionActionType
): GridVideoDimensionReducerState {
  switch (action.type) {
    case SET_GRID_VIDEO_DIMENSIONS:
      return {
        rowCount: action.payload.rowCount,
        colSize: action.payload.colSize
      };

    default:
      return state;
  }
}
