import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocalNetworkQuality } from "../../../hooks";
import { usePrevious } from "../../../hooks/usePrevious/UsePrevious";

import { BAD_NETWORK_QUALITY_THRESHOLD } from "../../../constants/meeting";
import { POOR_CONNECTION_REAPPEAR_TIMEOUT } from "../../../constants/timer";

const PoorConnectionStrip: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation("twilioRoom");
  const networkLevel = useLocalNetworkQuality();
  const previousNetworkLevel = usePrevious(networkLevel);

  const [isShowConnectionStrip, setIsShowConnectionStrip] = useState(false);
  const displayStripTimeout = useRef<any>();

  useEffect(() => {
    if (networkLevel && previousNetworkLevel) {
      if (
        networkLevel <= BAD_NETWORK_QUALITY_THRESHOLD &&
        !(previousNetworkLevel <= BAD_NETWORK_QUALITY_THRESHOLD) &&
        !displayStripTimeout.current
      ) {
        setIsShowConnectionStrip(true);
      } else if (
        isShowConnectionStrip &&
        networkLevel > BAD_NETWORK_QUALITY_THRESHOLD
      ) {
        setIsShowConnectionStrip(false);
      }
    }
  }, [networkLevel, previousNetworkLevel, isShowConnectionStrip]);

  useEffect(
    () => (): void => {
      if (displayStripTimeout.current)
        clearTimeout(displayStripTimeout.current);
    },
    []
  );

  const handlePoorConnectionStripClose = (): void => {
    setIsShowConnectionStrip(false);
    displayStripTimeout.current = setTimeout(() => {
      if (networkLevel && networkLevel <= BAD_NETWORK_QUALITY_THRESHOLD)
        setIsShowConnectionStrip(true);
      if (displayStripTimeout.current) {
        clearTimeout(displayStripTimeout.current);
        displayStripTimeout.current = undefined;
      }
    }, POOR_CONNECTION_REAPPEAR_TIMEOUT);
  };

  if (isShowConnectionStrip) {
    return (
      <div
        className="reconnecting-banner-container"
        onClick={handlePoorConnectionStripClose}
      >
        <div className="reconnecting-banner">
          <i className="ciq-icon ciq-component-icon-ic-connection-low" />
          <p>{t("poorConnectionMessage")}</p>

          <div className="close-icon">
            <span className="ciq-icon  ciq-component-icon-ic-close-circle" />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default PoorConnectionStrip;
