import { useMeetingContext } from "..";
import { CIQParticipant } from "../../models/meeting";
import { UtilService } from "../../services";

export default function useCIQParticipantData(
  identity: string
): CIQParticipant | undefined {
  const { meeting } = useMeetingContext();
  const meetingParticipants = meeting?.participants;

  const ciqParticipantDataMap = meetingParticipants
    ? UtilService.createParticipantDataMap(meetingParticipants)
    : new Map<string, CIQParticipant>();

  return ciqParticipantDataMap.get(identity);
}
