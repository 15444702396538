import React, { createContext, useMemo } from "react";

import { ZoomMeeting } from "../services/zoom/ZoomMeeting";
import { useScreenShare } from "./zoomActions/useScreenShare";

type IZoomSharing = ReturnType<typeof useScreenShare>;

const ZoomSharing = createContext<IZoomSharing>({} as IZoomSharing);

export function ZoomSharingProvider({
  zoomMeeting,
  children
}: {
  zoomMeeting: ZoomMeeting;
  children: React.ReactNode;
}) {
  const screenShare = useScreenShare(zoomMeeting);

  const contextValue = useMemo(
    (): IZoomSharing => screenShare,
    [screenShare.isSelfSharingScreen, screenShare.isPeerSharingScreen]
  );

  return (
    <ZoomSharing.Provider value={contextValue}>{children}</ZoomSharing.Provider>
  );
}

export function useZoomSharing() {
  return React.useContext(ZoomSharing);
}
