import React from "react";

import { useMeetingContext } from "../../hooks";
import { useNextJoinRequestId } from "../../hooks/useNextJoinRequest/UseNextJoinRequest";
import JoinRequestModal from "../joinRequestModal/JoinRequestModal";

const JoinRequests = (): JSX.Element => {
  const activeRequest = useNextJoinRequestId();
  const { joinRequests } = useMeetingContext();

  return (
    <>
      {joinRequests.map((jr) => (
        <JoinRequestModal
          key={jr.id}
          isToggled={activeRequest === jr.id}
          joinRequestId={jr.id}
          email={jr.email}
          userInfo={jr.userInfo}
        />
      ))}
    </>
  );
};

export default JoinRequests;
