// Room
export enum ROOM_STATE_TYPE {
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  RECONNECTED = "reconnected",
  RECONNECTING = "reconnecting"
}

// Participant
export enum PARTICIPANT_STATE {
  PARTICIPANT_CONNECTED = "participantConnected",
  PARTICIPANT_DISCONNECTED = "participantDisconnected",
  PARTICIPANT_RECONNECTING = "reconnecting",
  PARTICIPANT_RECONNECTED = "reconnected",
  PARTICIPANT_NETWORK_QUALITY = "networkQualityLevelChanged"
}

export enum TRACK_PUBLISH_STATE {
  TRACK_PUBLISHED = "trackPublished",
  TRACK_UNPUBLISHED = "trackUnpublished"
}

export enum PUBLICATION_STATE {
  SUBSCRIBED = "subscribed",
  UNSUBSCRIBED = "unsubscribed"
}

export enum MEDIA_STREAM_STATE {
  STARTED = "started"
}

export enum DOMINANT_SPEAKER_STATE {
  DOMINANT_SPEAKER_CHANGED = "dominantSpeakerChanged"
}

export enum DocumentType {
  PDF = "PDF",
  XLS = "XLS",
  CSV = "CSV",
  PRESENTATION = "PRESENTATION",
  DOC = "DOC",
  ZIP = "ZIP",
  EXCEL = "EXCEL",
  IMAGE = "IMAGE",
  EVENT = "EVENT",
  GENERIC = "GENERIC"
}

export const extensionToIconMap = new Map([
  ["pdf", DocumentType.PDF],
  ["xls", DocumentType.XLS],
  ["csv", DocumentType.CSV],
  ["ppt", DocumentType.PRESENTATION],
  ["pptx", DocumentType.PRESENTATION],
  ["doc", DocumentType.DOC],
  ["docx", DocumentType.DOC],
  ["zip", DocumentType.ZIP],
  ["7zip", DocumentType.ZIP],
  ["rar", DocumentType.ZIP],
  ["xlsx", DocumentType.EXCEL],
  ["xlt", DocumentType.EXCEL],
  ["mng", DocumentType.IMAGE],
  ["tiff", DocumentType.IMAGE],
  ["ico", DocumentType.IMAGE],
  ["bmp", DocumentType.IMAGE],
  ["png", DocumentType.IMAGE],
  ["jpeg", DocumentType.IMAGE],
  ["jpg", DocumentType.IMAGE],
  ["gif", DocumentType.IMAGE],
  ["event", DocumentType.EVENT],
  ["txt", DocumentType.GENERIC]
]);
