import { useEffect, useReducer } from "react";
import { useMediaQuery } from "react-responsive";

import { MAX_PARTICIPANT_VIEWS } from "../../constants/meeting";
import { APP_DEVICE_MEDIA_QUERIES } from "../../constants/ui";
import useParticipants from "../useParticipants/UseParticipants";
import {
  GridVideoDimensionReducerState,
  SET_GRID_VIDEO_DIMENSIONS,
  gridVideoDimensionReducer
} from "./GridVideoDimensionReducer";

interface UseVideoDimensions {
  colSize: number;
  rowCount: number;
}

function generateColSize(
  count: number,
  isMobile: boolean,
  isLandscape: boolean
): number {
  if (count === 1) {
    return 12;
  }
  if (count > 1 && count <= 4) {
    if (isMobile && isLandscape) {
      if (count === 2) {
        return 6;
      }
      if (count === 3) {
        return 4;
      }
      return 3;
    }
    return 6;
  }
  if (count >= 5 && count <= 8) {
    if (isMobile) {
      if (isLandscape) {
        return 3;
      }
      return 6;
    }
    return 4;
  }

  return isMobile ? 6 : 3;
}

function generateRowCount(
  count: number,
  isMobile: boolean,
  isLandscape: boolean
): number {
  if (isMobile && isLandscape) {
    return 1;
  }
  if (count <= 2) {
    return 1;
  }
  if (count > 2 && count <= 6) {
    return 2;
  }

  return isMobile ? 2 : 3;
}

function useGridGridVideoDimensions(): UseVideoDimensions {
  const participantsCount = useParticipants().length + 1;

  const isLandscape = useMediaQuery({ orientation: "landscape" });
  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });

  const virtualParticipantCount =
    participantsCount <= MAX_PARTICIPANT_VIEWS(!isLargeDevice)
      ? participantsCount
      : MAX_PARTICIPANT_VIEWS(!isLargeDevice);

  const [gridVideoDimensions, dispatch] = useReducer(
    gridVideoDimensionReducer,
    {
      colSize: generateColSize(
        virtualParticipantCount,
        !isLargeDevice,
        isLandscape
      ),
      rowCount: generateRowCount(
        virtualParticipantCount,
        !isLargeDevice,
        isLandscape
      )
    } as GridVideoDimensionReducerState
  );

  useEffect(() => {
    dispatch({
      type: SET_GRID_VIDEO_DIMENSIONS,
      payload: {
        colSize: generateColSize(
          virtualParticipantCount,
          !isLargeDevice,
          isLandscape
        ),
        rowCount: generateRowCount(
          virtualParticipantCount,
          !isLargeDevice,
          isLandscape
        )
      }
    });
  }, [virtualParticipantCount, isLargeDevice, isLandscape]);

  return gridVideoDimensions;
}

export default useGridGridVideoDimensions;
