import i18next from "i18next";
import * as Yup from "yup";
import { UserConstraints } from "../../../constants/validation";

export const joinTwilioMeetingSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(i18next.t("lobby:emailIsRequired"))
    .email(i18next.t("lobby:invalidEmail"))
    .max(
      UserConstraints.MAX_EMAIL_LENGTH,
      i18next.t("lobby:emailMaxLength", {
        length: UserConstraints.MAX_EMAIL_LENGTH
      })
    ),
  name: Yup.string()
    .trim()
    .required(i18next.t("lobby:nameIsRequired"))
    .max(
      UserConstraints.MAX_NAME_LENGTH,
      i18next.t("lobby:nameMaxLength", {
        length: UserConstraints.MAX_NAME_LENGTH
      })
    )
});
