import { Participant, VideoClient } from "@zoom/videosdk";
import { isEqual } from "lodash";

import { ZoomEvents } from "../../constants/zoomEvents";
import { getParticipantsAndRenameDialedIn } from "../../utils/zoom";

export const ADDED_PARTICIPANTS_EVENT = "addedParticipants";
export const REMOVED_PARTICIPANT_EVENT = "removedParticipant";
export const UPDATED_PARTICIPANTS_EVENT = "updatedParticipants";

enum Operator {
  Add,
  Remove
}

export class MeetingParticipants extends EventTarget {
  private client: typeof VideoClient;

  private _participants: Participant[] = [];

  get participants() {
    return this._participants;
  }

  // Can't use normal methods and ".bind" because it will create a new function
  // we need to keep the references to be able to do ".off" in "leave" method
  private onUserAdded = (payload: Participant[]) =>
    this.updateParticipants(payload, Operator.Add);
  private onUserRemoved = (payload: Participant[]) =>
    this.updateParticipants(payload, Operator.Remove);
  private onUserUpdated = (participants: Participant[]) =>
    this.modifyParticipantsData(participants);

  constructor(client: typeof VideoClient) {
    super();
    this.client = client;
  }

  join() {
    // Set initial participants
    this._participants = getParticipantsAndRenameDialedIn(this.client);
    this.dispatchEvent(
      new CustomEvent(UPDATED_PARTICIPANTS_EVENT, {
        detail: { participants: this._participants }
      })
    );

    // Don't set the callbacks before meeting is started - there's no UI to be updated
    this.client.on(ZoomEvents.UserAdded, this.onUserAdded);
    this.client.on(ZoomEvents.UserRemoved, this.onUserRemoved);
    this.client.on(ZoomEvents.UserUpdated, this.onUserUpdated);
  }

  leave() {
    this.client.off(ZoomEvents.UserAdded, this.onUserAdded);
    this.client.off(ZoomEvents.UserRemoved, this.onUserRemoved);
    this.client.off(ZoomEvents.UserUpdated, this.onUserUpdated);
  }

  private updateParticipants(
    changedParticipants: Participant[],
    operator: Operator
  ) {
    if (changedParticipants.length === 0) return;
    // Not using payload because it contains only the user that was added or removed
    // We need to store all users (except self)

    const peers = getParticipantsAndRenameDialedIn(this.client);
    const newIds = peers.map(({ userId }) => userId);
    const previousIds = this._participants.map(({ userId }) => userId);
    if (isEqual(previousIds, newIds)) return;

    if (operator === Operator.Remove) {
      for (const participant of changedParticipants) {
        this.dispatchEvent(
          new CustomEvent(REMOVED_PARTICIPANT_EVENT, {
            detail: { userId: participant.userId }
          })
        );
      }
    } else if (operator === Operator.Add) {
      this.dispatchEvent(
        new CustomEvent(ADDED_PARTICIPANTS_EVENT, {
          detail: { addedParticipants: changedParticipants }
        })
      );
    }

    this._participants = peers;
    this.dispatchEvent(
      new CustomEvent(UPDATED_PARTICIPANTS_EVENT, {
        detail: { participants: peers }
      })
    );
  }

  private modifyParticipantsData(modifiedParticipants: Participant[]) {
    if (modifiedParticipants.length === 0) return;

    const peers = getParticipantsAndRenameDialedIn(this.client);
    const peersToUpdate: any = peers.map((participant) => {
      if (participant.userId === modifiedParticipants[0].userId) {
        return {
          ...participant,
          ...modifiedParticipants[0]
        };
      }
      return participant;
    });

    this._participants = peersToUpdate;
    this.dispatchEvent(
      new CustomEvent(UPDATED_PARTICIPANTS_EVENT, {
        detail: { participants: peersToUpdate }
      })
    );
  }
}
