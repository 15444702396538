import { useEffect, useRef } from "react";
import { AudioTrack } from "twilio-video";
import { useMeetingContext } from "../../hooks";

interface ParticipantAudioTrackProps {
  track: AudioTrack;
}

export default function ParticipantAudioTrack({
  track
}: ParticipantAudioTrackProps): null {
  const { sinkId } = useMeetingContext();
  const audioEl = useRef<HTMLAudioElement>();

  useEffect(() => {
    audioEl.current = track.attach();
    audioEl.current.id = track.name;

    document.body.appendChild(audioEl.current);
    return (): void => track.detach().forEach((el) => el.remove());
  }, [track]);

  useEffect(() => {
    if (sinkId) audioEl.current?.setSinkId?.(sinkId);
  }, [sinkId]);

  return null;
}
