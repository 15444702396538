import { useEffect, useState } from "react";
import { RemoteParticipant } from "twilio-video";
import { useMeetingContext } from "..";
import {
  DOMINANT_SPEAKER_STATE,
  PARTICIPANT_STATE
} from "../../constants/events";

export default function useDominantSpeaker(): RemoteParticipant | null {
  const { room } = useMeetingContext();
  const [dominantSpeaker, setDominantSpeaker] = useState(room.dominantSpeaker);

  useEffect(() => {
    const { DOMINANT_SPEAKER_CHANGED } = DOMINANT_SPEAKER_STATE;

    const dominantSpeakerDisconnected = (
      participant: RemoteParticipant
    ): void => {
      setDominantSpeaker((prevDominantSpeaker) =>
        prevDominantSpeaker === participant ? null : prevDominantSpeaker
      );
    };

    room.on(DOMINANT_SPEAKER_CHANGED, (participant: RemoteParticipant) => {
      setDominantSpeaker(participant);
    });
    room.on(
      PARTICIPANT_STATE.PARTICIPANT_DISCONNECTED,
      dominantSpeakerDisconnected
    );
    return (): void => {
      room.off(DOMINANT_SPEAKER_CHANGED, (participant: RemoteParticipant) => {
        setDominantSpeaker(participant);
      });
      room.off(
        PARTICIPANT_STATE.PARTICIPANT_DISCONNECTED,
        dominantSpeakerDisconnected
      );
    };
  }, [room, dominantSpeaker]);

  return dominantSpeaker;
}
