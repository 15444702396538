import Axios, { Method, AxiosRequestConfig, AxiosError } from "axios";
import i18next from "i18next";
import queryString from "query-string";
import { Observable } from "rxjs";

import { APIConstants } from "../constants";
import { ErrorResponse } from "../models/api";

const axiosInstance = Axios.create({
  baseURL: APIConstants.API_URL,
  withCredentials: true
});

axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
axiosInstance.defaults.headers.common.Accept = "application/json";

axiosInstance.interceptors.response.use(
  undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error: AxiosError<any>) => {
    let errorResponse = {} as ErrorResponse;

    const status = error.response ? error.response.status : null;

    if (status === 500) {
      errorResponse.message = i18next.t("restService:somethingWrongTryLater");
      errorResponse.status = APIConstants.REST_ERROR.INTERNAL_ERROR;
      throw errorResponse;
    }

    if (error.response && error.response.data) {
      errorResponse = error.response.data as ErrorResponse;
      throw errorResponse;
    } else if (Axios.isCancel(error)) {
      errorResponse.message = i18next.t("restService:userCanceledRequest");
      errorResponse.status = APIConstants.REST_ERROR.REQUEST_CANCELED;
      throw errorResponse;
    } else if (error.message === "Network Error") {
      errorResponse.message = i18next.t("restService:noInternet");
      errorResponse.status = APIConstants.REST_ERROR.NETWORK_ERROR;
      throw errorResponse;
    } else {
      errorResponse.message = i18next.t("restService:somethingWrong");
      throw errorResponse;
    }
  }
);

const createUrl = (
  endPoint: string,
  queryParams: object | undefined = undefined
): string => {
  let params = "";

  if (queryParams) {
    const queries = { ...queryParams };
    params += `?${queryString.stringify(queries)}`;
  }

  return endPoint + params;
};

const createRequestOptions = (
  url: string,
  method: Method,
  data?: object,
  customConfig?: AxiosRequestConfig
): AxiosRequestConfig => {
  if (customConfig) {
    return {
      ...customConfig,
      url,
      method,
      data
    };
  }

  return {
    url,
    method,
    data
  };
};

const get = <T>(
  endPoint: string,
  queryParams: object | undefined = undefined
): Observable<T> => apiCall<T>("GET", endPoint, undefined, queryParams);

const post = <T>(
  endPoint: string,
  data: object,
  queryParams: object | undefined = undefined
): Observable<T> => apiCall<T>("POST", endPoint, data, queryParams);

const put = <T>(
  endPoint: string,
  data: object,
  queryParams: object | undefined = undefined,
  customConfig: AxiosRequestConfig = {}
): Observable<T> =>
  apiCall<T>("PUT", endPoint, data, queryParams, customConfig);

const patch = <T>(
  endPoint: string,
  data: object,
  queryParams: object | undefined = undefined
): Observable<T> => apiCall<T>("PATCH", endPoint, data, queryParams);

const deleteREST = <T>(
  endPoint: string,
  queryParams: object | undefined = undefined
): Observable<T> => apiCall<T>("DELETE", endPoint, undefined, queryParams);

const apiCall = <T>(
  method: Method,
  endPoint: string,
  data?: object,
  queryParams: object | undefined = undefined,
  customConfig?: AxiosRequestConfig
): Observable<T> => {
  const url = createUrl(endPoint, queryParams);
  const options = createRequestOptions(url, method, data, customConfig);

  return new Observable<T>((subscriber) => {
    axiosInstance
      .request<T>(options)
      .then((result) => {
        subscriber.next(result && result.data);
        subscriber.complete();
      })
      .catch((error: ErrorResponse) => {
        subscriber.error(error);
      });
  });
};

/** @deprecated legacy approach with axios and rxjs */
export const LegacyRestService = {
  get,
  post,
  put,
  patch,
  delete: deleteREST
};
