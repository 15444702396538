import { ErrorResponse } from "../../../models/api";
import { DialInStatus } from "../../../models/view/participants";
import { NotJoinedState } from "./NotJoinedParticipantItem";

export const CALL_STATUS_CHECK = "CALL_STATUS_CHECK";
export const CALL_STATUS_CHECK_SUCCESS = "CALL_STATUS_CHECK_SUCCESS";
export const CALL_STATUS_CHECK_FAILURE = "CALL_STATUS_CHECK_FAILURE";
export const DIAL_IN_PARTICIPANT = "DIAL_IN_PARTICIPANT";
export const DIAL_IN_PARTICIPANT_SUCCESS = "DIAL_IN_PARTICIPANT_SUCCESS";
export const DIAL_IN_PARTICIPANT_FAILURE = "DIAL_IN_PARTICIPANT_FAILURE";

interface CallStatusCheckType {
  type: typeof CALL_STATUS_CHECK;
}

interface CallStatusCheckSuccessType {
  type: typeof CALL_STATUS_CHECK_SUCCESS;
  payload: {
    dialInStatus: DialInStatus;
  };
}

interface CallStatusCheckFailureType {
  type: typeof CALL_STATUS_CHECK_FAILURE;
  payload: {
    error: ErrorResponse;
  };
}

interface DialInParticipantType {
  type: typeof DIAL_IN_PARTICIPANT;
}

interface DialInParticipantSuccessType {
  type: typeof DIAL_IN_PARTICIPANT_SUCCESS;
}

interface DialInParticipantFailureType {
  type: typeof DIAL_IN_PARTICIPANT_FAILURE;
  payload: {
    error: ErrorResponse;
  };
}

type NotJoinedReducerAction =
  | CallStatusCheckType
  | CallStatusCheckSuccessType
  | CallStatusCheckFailureType
  | DialInParticipantType
  | DialInParticipantSuccessType
  | DialInParticipantFailureType;

export function notJoinedReducer(
  state: NotJoinedState,
  action: NotJoinedReducerAction
): NotJoinedState {
  switch (action.type) {
    case CALL_STATUS_CHECK: {
      return {
        ...state,
        dialInStatus: DialInStatus.IDLE
      };
    }
    case CALL_STATUS_CHECK_SUCCESS: {
      return { ...state, dialInStatus: action.payload.dialInStatus };
    }
    case CALL_STATUS_CHECK_FAILURE: {
      return {
        ...state,
        dialInError: action.payload.error,
        dialInStatus: DialInStatus.IDLE
      };
    }
    case DIAL_IN_PARTICIPANT: {
      return {
        dialInError: undefined,
        dialInStatus: DialInStatus.QUEUED,
        loading: true
      };
    }
    case DIAL_IN_PARTICIPANT_SUCCESS: {
      return {
        loading: false,
        dialInStatus: DialInStatus.QUEUED
      };
    }
    case DIAL_IN_PARTICIPANT_FAILURE: {
      return {
        dialInError: action.payload.error,
        loading: false,
        dialInStatus: DialInStatus.IDLE
      };
    }
    default: {
      return state;
    }
  }
}
