import { interval } from "d3-timer";
import { useEffect, useState } from "react";
import { AudioTrack, LocalAudioTrack } from "twilio-video";

import { useIsTrackEnabled, useMediaStreamTrack } from "..";

interface UseAudioLevel {
  audioLevel: number;
}

const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext: AudioContext;

function initializeAnalyser(stream: MediaStream): AnalyserNode {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.4;
  analyser.fftSize = 512;

  audioSource.connect(analyser);
  return analyser;
}

const MAX_VARIATION_HEIGHT = 21;
const MIN_VALIDITY_LEVEL = 2;

function UseAudioLevel(audioTrack: AudioTrack): UseAudioLevel {
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const [audioLevel, setAudioLevel] = useState(0);
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      // Here we create a new MediaStream from a clone of the mediaStreamTrack.
      // A clone is created to allow multiple instances of this component for a single
      // AudioTrack on iOS Safari.
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);

      // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
      // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
      // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
      // from a new audio device while the active audio device still has active tracks.

      // Disabled due to a Chrome bug
      // https://github.com/twilio/twilio-video.js/issues/1811

      // const stopAllMediaStreamTracks = (): void =>
      //   newMediaStream.getTracks().forEach(track => track.stop());
      // audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = (): void => {
        // stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener("focus", reinitializeAnalyser);

      return (): void => {
        // stopAllMediaStreamTracks();
        window.removeEventListener("focus", reinitializeAnalyser);
        // audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isTrackEnabled && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const timer = interval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        const { length } = sampleArray;
        for (let i = 0; i < length; i += 1) {
          values += sampleArray[i];
        }

        const volume = Math.min(
          MAX_VARIATION_HEIGHT,
          Math.max(0, Math.log10(values / length / 3) * 14)
        );

        const cancelledLevel = MIN_VALIDITY_LEVEL <= volume ? volume : 0;

        setAudioLevel(Math.ceil(cancelledLevel));
      }, 50);

      return (): void => {
        timer.stop();
      };
    }
  }, [isTrackEnabled, analyser]);

  return { audioLevel: isTrackEnabled ? audioLevel : 0 };
}

export default UseAudioLevel;
