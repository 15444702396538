import { useCallback, useEffect, useState } from "react";
import { Participant, Room } from "twilio-video";
import { PARTICIPANT_STATE, ROOM_STATE_TYPE } from "../../constants/events";

export interface UseSelectedParticipant {
  selectedParticipant: Participant | undefined;
  chooseSelectedParticipant: (participant: Participant) => void;
}

export default function useSelectedParticipant(
  room: Room
): UseSelectedParticipant {
  const [selectedParticipant, setSelectedParticipant] = useState<
    Participant | undefined
  >();

  useEffect(() => {
    const { PARTICIPANT_DISCONNECTED } = PARTICIPANT_STATE;

    const onDisconnect = (): void => setSelectedParticipant(undefined);
    const handleParticipantDisconnected = (participant: Participant): void => {
      if (participant.identity === selectedParticipant?.identity)
        setSelectedParticipant(undefined);
    };

    room.on(ROOM_STATE_TYPE.DISCONNECTED, onDisconnect);
    room.on(PARTICIPANT_DISCONNECTED, handleParticipantDisconnected);

    return (): void => {
      room.off(ROOM_STATE_TYPE.DISCONNECTED, onDisconnect);
      room.off(PARTICIPANT_DISCONNECTED, handleParticipantDisconnected);
    };
  }, [room, selectedParticipant]);

  const chooseSelectedParticipant = useCallback((participant: Participant) => {
    setSelectedParticipant((preParticipant) =>
      participant.identity === preParticipant?.identity
        ? undefined
        : participant
    );
  }, []);

  return {
    selectedParticipant,
    chooseSelectedParticipant
  };
}
