import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { RECAPTCHA_SITE_KEY } from "../../../constants/app";
import { APP_DEVICE_MEDIA_QUERIES } from "../../../constants/ui";
import Header from "../../header/Header";
import SettingsModalToggle from "../../settingsModal/settingsToggle/SettingsModalToggle";
import JoinTwilioMeeting from "../joinTwilioMeeting/JoinTwilioMeeting";
import LocalAudioToggle from "../localAudioToggle/LocalAudioToggle";
import LocalVideoPreview from "../localVideoPreview/LocalVideoPreview";
import LocalVideoToggle from "../localVideoToggle/LocalVideoToggle";

import "./TwilioLobby.scss";

export default function TwilioLobby() {
  const { t } = useTranslation("lobby");

  useEffect(() => {
    document.title = t("pageTitle");
  }, [t]);

  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <div className="lobby-container">
        <header className="header-container">
          <Header />
          <div className="btn-container">
            {!isLargeDevice && <SettingsModalToggle />}
          </div>
        </header>
        <main className="middle-container">
          <div className="middle-limiter">
            <div className="video-preview-section">
              <LocalVideoPreview />
              <div className="local-controls">
                {isLargeDevice && <SettingsModalToggle />}
                <LocalAudioToggle />
                <LocalVideoToggle />
              </div>
            </div>
            <JoinTwilioMeeting />
          </div>
        </main>

        <footer className="footer-container" />
      </div>
    </GoogleReCaptchaProvider>
  );
}
