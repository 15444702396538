export enum MEETING_TYPE {
  INSTANT = "InstantMeeting",
  SCHEDULED = "ScheduledMeeting"
}

export enum GUEST_APPROVAL_STATE {
  APPROVED = "Approved",
  PENDING = "Pending",
  DECLINED = "Declined"
}

export enum STACK_MODE {
  GRID = "GRID",
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL"
}

export const MAX_PARTICIPANT_VIEWS = (isMobile: boolean): number =>
  isMobile ? 4 : 12;

export enum MEETING_STATUS {
  IN_PROGRESS = "InProgress"
}

export enum RECORDING_STATUS {
  NOT_APPLICABLE = "NotApplicable"
}

export enum USER_STATE {
  JOINED = "Joined",
  LEFT = "Left"
}

export const SCREEN_SHARE_TRACK_NAME = "screen";

// Participant bad network quality level
export const BAD_NETWORK_QUALITY_THRESHOLD = 1;
