import React from "react";
import { useTranslation } from "react-i18next";
import { useMeetingContext } from "../../../../hooks";

const RecordingIndicatorStatus: React.FC = (): JSX.Element => {
  const { t } = useTranslation("recordingIndicator");
  const { setRecording, isRecordingCounter, isRecording } = useMeetingContext();

  let recordingLabel = "";
  if (isRecording) {
    recordingLabel = t("pause");
  }
  if (!isRecording) {
    if (isRecordingCounter > 0) {
      recordingLabel = t("resume");
    } else {
      recordingLabel = t("start");
    }
  }

  return (
    <div className="record-indicator-container">
      <div className="record-indicator">
        <div className={`record-dot${!isRecording ? "-inactive" : ""}`}>
          <span className={`record-dot-2${!isRecording ? "-inactive" : ""}`} />
        </div>

        <span>{t("recording")}</span>
        <span className="record-action" onClick={setRecording}>
          {recordingLabel}
        </span>
      </div>
    </div>
  );
};

export default RecordingIndicatorStatus;
