import { API_URL } from "../constants/api";

type URLParams = Record<string, string>;
type APIPayload = Record<string, unknown>;

async function get<T = Record<string, unknown>>(
  url: string,
  params?: URLParams
) {
  const parsedParams = new URLSearchParams(params);
  const parsedUrl = new URL(`${API_URL}${url}`);
  parsedUrl.search = parsedParams.toString();

  const response = await fetch(parsedUrl.toString(), { mode: "cors" });

  if (!response.ok) {
    throw new RESTClientError({ url, params }, { status: response.status });
  }

  return (await response.json()) as T;
}

async function post<T = Record<string, unknown>>(
  url: string,
  payload?: APIPayload
) {
  const response = await fetch(`${API_URL}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload ?? {})
  });

  if (!response.ok) {
    throw new RESTClientError({ url, payload }, { status: response.status });
  }

  return (await response.json()) as T;
}

interface IRestClientErrorRequest {
  url: string;
  params?: URLParams;
  payload?: APIPayload;
}

interface IRestClientErrorResponse {
  status?: number;
}

class RESTClientError extends Error {
  request: IRestClientErrorRequest;
  response: IRestClientErrorResponse;

  constructor(
    request: IRestClientErrorRequest,
    response: IRestClientErrorResponse
  ) {
    super("API error");
    this.name = "RESTClientError";
    this.request = request;
    this.response = response;
  }

  toJSON() {
    return {
      response: JSON.stringify(this.response),
      request: JSON.stringify(this.request)
    };
  }
}

export const SimpleRestService = {
  get,
  post
};
