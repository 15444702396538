export const UI_WINDOW_HEIGHT = "var(--vh100)";

export const APP_DEVICE_MEDIA_QUERIES = {
  small: "(max-width: 768px)",
  medium: "(min-width: 768px) and (max-width: 1023px)",
  large: "(min-width: 1024px)"
};

export const UI_ROOM = {
  ROOM_EXCESS_HEIGHT: 80,
  PARTICIPANT_LIST_HEIGHT: (isLargeDevice: boolean): number =>
    isLargeDevice ? 200 : 180
};

export const UI_PARTICIPANT_LIST = {
  MEETING_INFO_CONTAINER: (
    large: boolean,
    isGuest: boolean,
    dialInNumbers: number
  ): number =>
    (large ? 212 : 145) +
    dialInNumbers * UI_PARTICIPANT_LIST.DIAL_NUMBER_HEIGHT(large) -
    (isGuest ? 24 : 0),
  PARTICIPANT_LIST_HEIGHT: (
    large: boolean,
    isGuest: boolean,
    dialInNumbers: number
  ): string =>
    `calc(${UI_WINDOW_HEIGHT} - ${
      (large ? 173 : 171) +
      UI_PARTICIPANT_LIST.MEETING_INFO_CONTAINER(large, isGuest, dialInNumbers)
    }px)`,
  PARTICIPANT_LIST_CONTAINER_HEIGHT: (small: boolean): string =>
    `calc(${UI_WINDOW_HEIGHT} - ${small ? 126 : 149}px)`,
  DIAL_NUMBER_HEIGHT: (large: boolean): number => (large ? 28 : 24)
};

export const UI_DIAL_IN_TAB = {
  DIAL_IN_CONTAINER_HEIGHT: (isSmall: boolean): string =>
    `calc(${UI_WINDOW_HEIGHT} - ${isSmall ? 126 : 149}px)`
};

export const UI_ZOOM_CONFIG_MODAL = {
  MODAL_WIDTH: 752,
  MODAL_HEIGHT: 786
};

export const ICON_SIZE = {
  SMALL: "14px",
  MEDIUM: "16px",
  LARGE: "20px"
};
