import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import clsx from "clsx";
import { CIQTooltip } from "../..";
import { useMeetingContext, useScreenShareParticipant } from "../../../hooks";

export function ShareScreenToggle(): JSX.Element {
  const { toggleScreenShare, isScreenSharing } = useMeetingContext();
  const { t } = useTranslation("twilioRoom");
  const screenShareParticipant = useScreenShareParticipant();

  const isScreenShareSupported =
    // eslint-disable-next-line @typescript-eslint/unbound-method
    navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;

  const hasScreenShare = !!screenShareParticipant;

  let sharingTooltipKey = t("presentScreen");

  if (!isScreenShareSupported) {
    sharingTooltipKey = t("screenShareNotSupport");
  } else if (isScreenSharing) {
    sharingTooltipKey = t("stopScreenShareTooltip");
  } else if (hasScreenShare) {
    sharingTooltipKey = t("cannotShareScreen");
  }

  return (
    <span data-tip data-for="local-screen-present-btn-tool-tip">
      <Button
        className={clsx("circle-btn screen-btn outline", {
          "screen-on": isScreenSharing,
          disabled:
            (hasScreenShare && !isScreenSharing) || !isScreenShareSupported
        })}
        size="lg"
        onClick={toggleScreenShare}
      >
        <i className="ciq-icon ciq-call-present" />
      </Button>
      <CIQTooltip
        id="local-screen-present-btn-tool-tip"
        content={sharingTooltipKey}
      />
    </span>
  );
}
