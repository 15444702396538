import { useDevices } from "..";
import { DEVICE_TYPES } from "../../constants/media";
import { SelectOption } from "../../models/modal";

export function useVideoInputDevices(): SelectOption[] {
  const devices = useDevices();
  return devices
    .filter((device) => device.kind === DEVICE_TYPES.VIDEO_INPUT)
    .map((d) => ({ label: d.label, value: d.deviceId }));
}
