import clsx from "clsx";
import React from "react";
import { Col } from "reactstrap";
import { STACK_MODE } from "../../constants/meeting";
import {
  useMainParticipant,
  useMeetingContext,
  useParticipants,
  useStackMode
} from "../../hooks";
import ParticipantCard from "../twilioRoom/participant/ParticipantCard";

export const MainParticipant: React.FC = () => {
  const mainParticipant = useMainParticipant();
  const { isParticipantSidebarOpen, selectedParticipant } = useMeetingContext();
  const {
    room: { localParticipant }
  } = useMeetingContext();

  const participantsCount = useParticipants().length;

  const isLocalParticipantOnly = participantsCount === 0;

  const stackMode = useStackMode();

  const { VERTICAL } = STACK_MODE;

  let columnSize = 12;

  // STACK MODE will be vertical only on screen sharing
  if (stackMode === VERTICAL) {
    if (!selectedParticipant && !isLocalParticipantOnly) {
      columnSize = 10;
    }
  }

  return (
    <Col
      xs={columnSize}
      className={clsx("main-participant col", {
        toggled: isParticipantSidebarOpen
      })}
    >
      {mainParticipant && (
        <ParticipantCard
          participant={mainParticipant}
          isLocalParticipant={mainParticipant === localParticipant}
          isMainParticipant
          hideParticipant={false}
        />
      )}
    </Col>
  );
};
