import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { CIQLoader } from "../..";
import { GUEST_APPROVAL_STATE } from "../../../constants/meeting";
import { useMeetingContext } from "../../../hooks";

enum JOINING_STATES {
  MIC_LOADING,
  VIDEO_LOADING,
  ASK_JOINING_LOADING,
  JOINING
}

const LOADING_STATES = new Map([
  [
    JOINING_STATES.MIC_LOADING,
    { icon: "ciq-call-audio-on", text: i18next.t("lobby:micTapping") }
  ],
  [
    JOINING_STATES.VIDEO_LOADING,
    { icon: "ciq-call-video-on", text: i18next.t("lobby:wavingAtCamera") }
  ],
  [
    JOINING_STATES.ASK_JOINING_LOADING,
    { icon: "ciq-people", text: i18next.t("lobby:askingToJoin") }
  ],
  [
    JOINING_STATES.JOINING,
    { icon: "ciq-check", text: i18next.t("lobby:connecting") }
  ]
]);

const JoinLoadingState: React.FC = (): JSX.Element => {
  const { isConnecting, approvalType, isCamRequesting, isMicRequesting } =
    useMeetingContext();
  const [state, setState] = useState<JOINING_STATES>(
    JOINING_STATES.MIC_LOADING
  );

  useEffect(() => {
    if (isMicRequesting) {
      setState(JOINING_STATES.MIC_LOADING);
    } else if (isCamRequesting) {
      setState(JOINING_STATES.VIDEO_LOADING);
    } else if (approvalType === GUEST_APPROVAL_STATE.PENDING) {
      setState(JOINING_STATES.ASK_JOINING_LOADING);
    } else {
      setState(JOINING_STATES.JOINING);
    }
  }, [isConnecting, approvalType, isCamRequesting, isMicRequesting]);

  const currentState = LOADING_STATES.get(state)!;

  return (
    <div className="loading-state">
      <div className="left-container">
        <span className={`ciq-icon ${currentState.icon}`} />
        <span className="state-text">{currentState.text}</span>
      </div>
      <div className="right-container">
        <CIQLoader loaderColor="purple" />
      </div>
    </div>
  );
};

export default JoinLoadingState;
