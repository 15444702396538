import clsx from "clsx";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, FormGroup, Input, Label } from "reactstrap";
import logoSquare from "../../assets/images/logo/logo-connect.svg";
import logoTree from "../../assets/images/logo/tree-white.svg";

import { MEETING_ROUTE } from "../../constants/routes";
import { MEETING_ID_REGEX } from "../../constants/validation";

type ConnectMeetingFormValues = {
  meetingId: string;
};

const WelcomePage: React.FC = (): JSX.Element => {
  const { t } = useTranslation("welcomePage");
  const { push } = useHistory();

  useEffect(() => {
    document.title = t("pageTitle");
  }, [t]);

  const { register, handleSubmit, errors, formState } =
    useForm<ConnectMeetingFormValues>({
      mode: "all",
      reValidateMode: "onSubmit"
    });

  const onSubmit = handleSubmit(({ meetingId }) => {
    push(MEETING_ROUTE(meetingId));
  });

  const disableJoinNow = !formState.isValid;

  return (
    <div className="welcome-page">
      <div className="left-container">
        <div className="welcome-message">
          <div className="logo">
            <img src={logoSquare} alt="arbolus connect logo tree" />
          </div>
          <h3>{t("welcomeIntro")}</h3>
          <h4>{t("welcomeTitle")}</h4>
        </div>
        <div className="form-container">
          <form onSubmit={onSubmit} className="meeting-id-input">
            <FormGroup className={clsx({ "is-invalid": errors.meetingId })}>
              <Input
                innerRef={register({
                  required: {
                    value: true,
                    message: t("errorMessage")
                  },
                  pattern: {
                    value: MEETING_ID_REGEX,
                    message: t("invalidMessage")
                  }
                })}
                name="meetingId"
                autoComplete="off"
                placeholder={t("meetingIdPlaceholder")}
                type="text"
              />
              {errors.meetingId && (
                <Label className="invalid-feedback">
                  {errors.meetingId.message}
                </Label>
              )}
            </FormGroup>

            <Button
              type="submit"
              color="primary"
              size="lg"
              disabled={disableJoinNow}
            >
              {t("connectToMeeting")}
            </Button>
          </form>
        </div>
      </div>
      <div className="right-container">
        <div className="background-overlay" />
        <div className="background-top-overlay">
          <span />
        </div>
        <div className="logo">
          <img src={logoTree} alt="arbolus connect logo tree" />
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
