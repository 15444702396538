import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { CIQTooltip } from "../..";
import { useLocalAudioToggle, useMeetingContext } from "../../../hooks";

const LocalAudioToggle: React.FC = (): JSX.Element => {
  const { isMicRequesting, isConnecting } = useMeetingContext();
  const { isEnabled, toggleLocalAudio, isMicPublishing } =
    useLocalAudioToggle();
  const { t } = useTranslation("twilioRoom");

  const buttonDisabled = isMicRequesting || isMicPublishing || isConnecting;

  return (
    <Button
      size="lg"
      disabled={buttonDisabled}
      onClick={toggleLocalAudio}
      className={clsx("circle-btn mic-btn", {
        off: !isEnabled,
        outline: isEnabled,
        disabled: buttonDisabled
      })}
      data-tip
      data-for="local-audio-toggle-tool-tip"
    >
      <i
        className={clsx("ciq-icon", {
          "ciq-call-audio-on": isEnabled,
          "ciq-call-audio-off": !isEnabled
        })}
      />
      <CIQTooltip
        id="local-audio-toggle-tool-tip"
        content={t(isEnabled ? "muteMic" : "unmuteMic")}
      />
    </Button>
  );
};

export default LocalAudioToggle;
