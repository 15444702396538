import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { MAX_PARTICIPANT_VIEWS } from "../constants/meeting";
import { APP_DEVICE_MEDIA_QUERIES } from "../constants/ui";

interface UseMaxVideoViewsCounts {
  maxViewsCount: number;
}

function useMaxVideoViewsCounts(): UseMaxVideoViewsCounts {
  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });

  const [maxViewsCount, setMaxViewCount] = useState(
    MAX_PARTICIPANT_VIEWS(!isLargeDevice)
  );

  useEffect(() => {
    setMaxViewCount(MAX_PARTICIPANT_VIEWS(!isLargeDevice));
  }, [isLargeDevice]);

  return { maxViewsCount };
}

export default useMaxVideoViewsCounts;
