import React from "react";
import { Row } from "reactstrap";
import { Participant } from "twilio-video";
import { ParticipantCard } from "../..";
import {
  useMainParticipant,
  useMeetingContext,
  useParticipants,
  useScreenShareParticipant
} from "../../../hooks";

import useMaxVideoViewsCounts from "../../../hooks/useMaxVideoViewsCounts";

const ParticipantsList: React.FC = (): JSX.Element => {
  const { room } = useMeetingContext();
  const mainParticipant = useMainParticipant();
  const { maxViewsCount } = useMaxVideoViewsCounts();
  const screenShareParticipant = useScreenShareParticipant();
  const participants = useParticipants();

  const { localParticipant } = room;

  const isLocalMainParticipant = mainParticipant === localParticipant;

  const handleHideCard = (participant: Participant, index: number): boolean => {
    if (screenShareParticipant) {
      return participant === screenShareParticipant;
    }

    const virtualMaxViewCount =
      maxViewsCount + (isLocalMainParticipant ? 1 : 0);
    return index + 1 >= virtualMaxViewCount;
  };

  return (
    <Row className="participant-card-container">
      {!isLocalMainParticipant && (
        <ParticipantCard
          hideParticipant={false}
          key={localParticipant?.identity}
          participant={localParticipant}
          isLocalParticipant
        />
      )}
      {participants.map((p, index) => (
        <ParticipantCard
          key={p.identity}
          hideParticipant={handleHideCard(p, index)}
          participant={p}
          isLocalParticipant={false}
        />
      ))}
    </Row>
  );
};

export default ParticipantsList;
