import React from "react";
import { useEffect } from "react";

import { useZoomSharing } from "../../contexts/ZoomSharingProvider";

import "./ScreenShare.scss";

// Careful! Those names are used in "ScreenShare.scss"
export const SELF_SCREEN_SHARE_VIDEO = "self-screen-share-video";
export const SELF_SCREEN_SHARE_CANVAS = "self-screen-share-canvas";
export const PEER_SCREEN_SHARE_CANVAS = "peer-screen-share-canvas";

export const ScreenShare = React.memo(() => {
  const {
    isSelfSharingScreen,
    isPeerSharingScreen,
    registerShareReceive,
    unregisterShareReceive
  } = useZoomSharing();

  useEffect(() => {
    // Can't be instantiated before this component is rendered
    registerShareReceive();
    return () => unregisterShareReceive();
  }, []);

  return (
    <div>
      <div
        id="self-screen-share-wrapper"
        style={{
          display: isSelfSharingScreen ? "block" : "none"
        }}
      >
        <video id={SELF_SCREEN_SHARE_VIDEO} height="0" />
        <canvas id={SELF_SCREEN_SHARE_CANVAS} height="0" />
      </div>
      <canvas
        id={PEER_SCREEN_SHARE_CANVAS}
        style={{
          display: isPeerSharingScreen ? "block" : "none"
        }}
      />
    </div>
  );
});
