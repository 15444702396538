import { createContext } from "react";

import { UseEventSidebar } from "../hooks/appContext/useEventSidebar/useEventSidebar";
import { UseJoinRequests } from "../hooks/appContext/useJoinRequests/UseJoinRequest";
import { UseLocalTracks } from "../hooks/appContext/useLocalTracks/UseLocalTracks";
import { UseParticipantSidebar } from "../hooks/appContext/useParticipantSidebar/useParticipantSidebar";
import { UseRoom } from "../hooks/appContext/useRoom/UseRoom";
import { UseShareScreen } from "../hooks/appContext/useShareScreen/useShareScreen";
import { UseSelectedParticipant } from "../hooks/useSelectedParticipant/UseSelectedParticipant";

import { Timezone } from "../models/meeting";

export interface MeetingContextState
  extends UseRoom,
    UseLocalTracks,
    UseParticipantSidebar,
    UseShareScreen,
    UseSelectedParticipant,
    UseEventSidebar,
    UseJoinRequests {
  sinkId?: string;
  setSinkId: (sinkId: string) => void;

  timezones: Timezone[];
  setTimezones: (timezoneDisplayText: Timezone[]) => void;
  getTimezoneDisplayText: () => string;
}

export const MeetingContext = createContext<MeetingContextState>(null!);

export const MeetingContextProvider = MeetingContext.Provider;
export const MeetingContextConsumer = MeetingContext.Consumer;
