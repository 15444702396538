import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { AudioTrack, RemoteTrackPublication } from "twilio-video";

import { useIsTrackEnabled, useMeetingContext, useTrack } from "../../../hooks";

interface LocalScreenShareCardProps {
  name: string;
  audioPublication?: RemoteTrackPublication;
}

const LocalScreenShareCard: React.FC<LocalScreenShareCardProps> = ({
  name,
  audioPublication
}): JSX.Element => {
  const { t } = useTranslation("twilioRoom");
  const { toggleScreenShare } = useMeetingContext();
  const audioTrack = useTrack(audioPublication);
  const isAudioEnabled = useIsTrackEnabled(audioTrack as AudioTrack);

  return (
    <div className="meta-container">
      <div className="screen-share-placeholder-container">
        <div className="presenting-container">
          <span className="screen-share-icon">
            <i className="ciq-icon ciq-call-present" />
          </span>
          <p>{t("screenSharing")}</p>
          <Button
            onClick={(
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ): void => {
              e.stopPropagation();
              toggleScreenShare();
            }}
            size="md"
            color="primary"
          >
            {t("stopScreenShare")}
          </Button>
        </div>
      </div>
      <div className="bottom-container">
        <div
          className={clsx("mic-btn", {
            disabled: !isAudioEnabled
          })}
        >
          <i
            className={clsx("ciq-icon", {
              "ciq-call-audio-on": isAudioEnabled,
              "ciq-call-audio-off": !isAudioEnabled
            })}
          />
        </div>

        <div className="name-container">
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
};

export default LocalScreenShareCard;
