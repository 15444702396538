import toastr from "toastr";

export enum TOAST_POSITION {
  BOTTOM_LEFT = "toast-bottom-left",
  BOTTOM_RIGHT = "toast-bottom-right",
  BOTTOM_CENTER = "toast-bottom-center"
}

const TOAST_CLASS = "custom-toast";

const TOAST_CONFIG: ToastrOptions = {
  toastClass: TOAST_CLASS,
  positionClass: TOAST_POSITION.BOTTOM_RIGHT,
  containerId: "toast-container",
  closeButton: true,
  timeOut: 5000,
  closeOnHover: false
};

class NotificationManager {
  private toastInstance?: JQuery<HTMLElement>;

  clearToast = (positionClass: string): void => {
    if (this.toastInstance) {
      this.toastInstance.remove();
      this.toastInstance = undefined;
    }

    // Revert parent container
    this.changeParentPosition(positionClass);
  };

  changeParentPosition = (className: string): void => {
    const parentContainer = document.getElementById("toast-container");
    if (parentContainer) {
      parentContainer.className = className;
    }
  };

  showSuccess = (
    message: string,
    positionClass = TOAST_POSITION.BOTTOM_RIGHT
  ): void => {
    this.clearToast(positionClass);
    this.toastInstance = toastr.success(message, "", TOAST_CONFIG);
    this.toastInstance.show();
  };

  showError = (
    message: string,
    positionClass = TOAST_POSITION.BOTTOM_RIGHT
  ): void => {
    this.clearToast(positionClass);
    this.toastInstance = toastr.error(message, "", TOAST_CONFIG);
    this.toastInstance.show();
  };

  showWarning = (
    message: string,
    positionClass = TOAST_POSITION.BOTTOM_RIGHT
  ): void => {
    this.clearToast(positionClass);
    this.toastInstance = toastr.warning(message, "", TOAST_CONFIG);
    this.toastInstance.show();
  };

  showInfo = (
    message: string,
    positionClass = TOAST_POSITION.BOTTOM_RIGHT
  ): void => {
    this.clearToast(positionClass);
    this.toastInstance = toastr.info(message, "", TOAST_CONFIG);
    this.toastInstance.show();
  };
}

export default new NotificationManager();
