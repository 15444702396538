import { useCallback, useEffect, useState } from "react";

import { ZoomMeeting } from "../../services/zoom/ZoomMeeting";

/*
 * Don't use more than 1 instance of this hook since it has event handlers.
 */
export function useScreenShare(zoomMeeting: ZoomMeeting) {
  const [isSelfSharingScreen, setIsSelfSharingScreen] = useState(false);
  const [isPeerSharingScreen, setIsPeerSharingScreen] = useState(false);

  const startSharingScreen = useCallback(() => {
    zoomMeeting.shareMyScreen().then(() => {
      setIsSelfSharingScreen(true);
    });
  }, []);

  const stopSharingScreen = useCallback(() => {
    zoomMeeting.stopSharingScreen().then(() => {
      setIsSelfSharingScreen(false);
    });
  }, []);

  const handlePassivelyStopShare = useCallback(() => {
    setIsSelfSharingScreen(false);
  }, []);

  const getSharingScreenUser = useCallback(() => {
    return zoomMeeting.getSharingScreenUser();
  }, []);

  useEffect(() => {
    zoomMeeting.registerOnPassivelyStopShare(handlePassivelyStopShare);
    return () =>
      zoomMeeting.unRegisterOnPassivelyStopShare(handlePassivelyStopShare);
  }, []);

  const handleActiveShareChange = useCallback((payload: any) => {
    if (payload.state === "Active") {
      zoomMeeting
        .startShareView(payload.userId)
        .then(() => setIsPeerSharingScreen(true));
    } else if (payload.state === "Inactive") {
      zoomMeeting.stopShareView();
      setIsPeerSharingScreen(false);
    }
  }, []);

  const registerShareReceive = useCallback(() => {
    const user = getSharingScreenUser();
    if (user.isSomeoneSharing && user.activeShareUserId) {
      zoomMeeting
        .startShareView(user.activeShareUserId)
        .then(() => setIsPeerSharingScreen(true));
    }
    return zoomMeeting.registerOnActiveShareChange(handleActiveShareChange);
  }, [getSharingScreenUser, handleActiveShareChange, zoomMeeting]);

  const unregisterShareReceive = useCallback(
    () => zoomMeeting.unRegisterOnActiveShareChange(handleActiveShareChange),
    []
  );

  return {
    isSelfSharingScreen,
    isPeerSharingScreen,
    sharingActive: isSelfSharingScreen || isPeerSharingScreen,
    startSharingScreen,
    stopSharingScreen,
    registerShareReceive,
    unregisterShareReceive
  };
}
