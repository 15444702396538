import i18next from "i18next";
import * as Yup from "yup";
import { UtilService } from "../../../services";

export const dialInSchema = (dialInNumbers: string[]) =>
  Yup.object().shape({
    phoneNumber: Yup.string()
      .required(i18next.t("room:phoneNumberRequired"))
      .test("phone-valid", i18next.t("room:phoneInvalid"), (val) =>
        UtilService.validateContactNumber(`+${val}`)
      )
      // eslint-disable-next-line func-names
      .test(
        "not-equal-dial-in",
        i18next.t("room:sameAsMeetingDialIn"),
        function test() {
          const { phoneNumber } = this.parent;
          return !dialInNumbers.includes(`+${phoneNumber}`);
        }
      )
  });
