import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import { CIQSelect } from "../..";

import {
  useAudioInputDevices,
  useIsTrackEnabled,
  useMediaStreamTrack,
  useMeetingContext,
  useModalContext
} from "../../../hooks";

export function AudioInputDevices(): JSX.Element {
  const { t } = useTranslation("settingsModal");
  const { localAudioTrack } = useMeetingContext();

  const { setSelectedAudioDevice, selectedAudioDeviceId } = useModalContext();
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const audioInputDevices = useAudioInputDevices();
  const isAudioTrackEnabled = useIsTrackEnabled(localAudioTrack);

  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  const selectedDeviceOption = audioInputDevices.find(
    (d) => d.value === (localAudioInputDeviceId || selectedAudioDeviceId)
  );

  const hasDevices = audioInputDevices.length > 0;

  const replaceTrack = (newDeviceId: string): void => {
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
    setSelectedAudioDevice(newDeviceId);
  };

  return (
    <div className="audio-input-list">
      <Label className="space-between">
        {t("microphone")}&nbsp;
        <span>{!isAudioTrackEnabled && t("microphoneOff")}</span>
      </Label>
      {hasDevices ? (
        <CIQSelect
          defaultValue={selectedDeviceOption}
          options={audioInputDevices}
          onSelectChange={(value: string): void => replaceTrack(value)}
          noOptionsMessage={t("noOptions")}
          placeholder={t("selectAudioInput")}
          customStyle
        />
      ) : (
        <p>{t("noAudioInput")}</p>
      )}
    </div>
  );
}
