/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";

import { UtilService } from "../../services";

interface AvatarImageProps {
  username: string;
  profileImageUrl?: string;
  imageClass?: string;
  isDialInParticipant?: boolean;
  fallBackImageClass?: string;
  isCalling?: boolean;
}

const AvatarImage: React.FC<AvatarImageProps> = ({
  username,
  profileImageUrl,
  imageClass = "user-avatar",
  fallBackImageClass = "fallback-avatar",
  isDialInParticipant = false,
  isCalling = false
}) => {
  const [error, setError] = useState<boolean>(false);
  const avatarName = UtilService.nameToAvatarText(username);

  useEffect(() => {
    setError(!profileImageUrl);
  }, [profileImageUrl]);

  const renderDialInParticipants = (): JSX.Element =>
    isCalling ? (
      <div className="avatar-container">
        <div
          className={
            isDialInParticipant
              ? "ciq-icon ciq-icon-ic-on-phone-dial-in"
              : fallBackImageClass
          }
        >
          {!isDialInParticipant && avatarName}
        </div>
      </div>
    ) : (
      <div className="avatar-container">
        <div
          className={
            isDialInParticipant
              ? "ciq-icon ciq-component-icon-ic-on-phone"
              : fallBackImageClass
          }
        >
          {!isDialInParticipant && avatarName}
        </div>
      </div>
    );

  return error ? (
    renderDialInParticipants()
  ) : (
    <div className="avatar-container">
      <img
        className={imageClass}
        src={profileImageUrl}
        onError={(): void => setError(true)}
        alt={avatarName}
      />
    </div>
  );
};

export default AvatarImage;
