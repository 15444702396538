import ZoomVideo, {
  Stream,
  VideoClient,
  LocalVideoTrack
} from "@zoom/videosdk";

import { PreviewTarget } from "../../constants/enums";
import { debug } from "../../utils/logging";

export class MeetingVideo {
  private client: typeof VideoClient;
  private stream: typeof Stream | undefined;

  private videoPreviewElement: HTMLVideoElement | undefined;
  private localVideoTrack: LocalVideoTrack | undefined;

  cameraId: string | undefined;

  constructor(client: typeof VideoClient) {
    this.client = client;
  }

  async join(stream: typeof Stream, isVideoPreviewStopped = false) {
    this.stream = stream;

    if (!isVideoPreviewStopped) {
      await this.startSelfVideo();
    }
  }

  async leave() {
    await this.stopVideoPreview();
    try {
      await this.stream?.stopVideo();
      await this.stream?.detachVideo(this.client.getCurrentUserInfo().userId);
    } catch (error) {
      // No need to act on this error since we're leaving anyways
      console.error({ error });
    }
  }

  // Video preview is used in settings - it's not the same as self video in the meeting!

  async startVideoPreview(deviceId: string, target: PreviewTarget) {
    this.videoPreviewElement = document.getElementById(
      `zoom-self-video-preview-${target}`
    ) as HTMLVideoElement | undefined;
    if (!this.videoPreviewElement) {
      debug("Video preview target element not found!");
      return;
    }

    this.localVideoTrack = ZoomVideo.createLocalVideoTrack(deviceId);
    try {
      if (target !== PreviewTarget.Modal) {
        this.togglePlaceholderPreview();
      }
      await this.localVideoTrack.start(this.videoPreviewElement);
    } catch (error) {
      if (error.name === "NotAllowedError") {
        debug("Camera access denied!");
      } else {
        throw error;
      }
    }
  }

  async stopVideoPreview() {
    if (!this.localVideoTrack) return;
    try {
      await this.localVideoTrack.stop();
      this.togglePlaceholderPreview();
    } catch (error) {
      if (error.message === "VideoNotStartedError") {
        debug("Can't stop self video preview - video not started!");
      } else {
        throw error;
      }
    }
    this.localVideoTrack = undefined;
  }

  private togglePlaceholderPreview() {
    const placeholder = document.getElementById("no-video-placeholder");
    if (placeholder) {
      const computedStyle = window.getComputedStyle(placeholder);
      if (computedStyle.display === "block") {
        placeholder.style.display = "none";
      } else {
        placeholder.style.display = "block";
      }
    }
  }

  // Video of current user (self) inside the meeting

  async startSelfVideo() {
    if (!this.stream) throw new Error("Stream not initialized!");

    if (!this.stream.isCapturingVideo()) {
      try {
        await this.stream.startVideo({
          cameraId: this.cameraId,
          hd: true,
          mirrored: true
        });
      } catch (error) {
        if (error.type === "VIDEO_USER_FORBIDDEN_CAPTURE") {
          debug("User denied camera access, adding placeholder tile");
          return;
        } else {
          throw error;
        }
      }
    }
  }

  async stopSelfVideo() {
    if (!this.stream) throw new Error("Stream not initialized!");

    try {
      await this.stream.stopVideo();
    } catch (error) {
      if (error.reason === "camera is closed") {
        debug("Can't stop self video - camera is closed!");
        return;
      } else {
        throw error;
      }
    }
  }

  // Device management

  async getCameraList() {
    const devices = await ZoomVideo.getDevices();
    const videoDevices = devices.filter(
      // A device with an empty ID is just a placeholder, not real one
      (device) => device.kind === "videoinput" && device.deviceId.length > 0
    );
    return videoDevices;
  }

  async switchCameraInput(deviceId: string) {
    this.cameraId = deviceId;
    await this.stream?.switchCamera(deviceId);
  }
}
