import { useEffect, useState } from "react";
import { DEVICE_ENUMERATION_STACKS } from "../../constants/errors";
import { AnalyticsService } from "../../services";

export function useDevices(): MediaDeviceInfo[] {
  const [mediaDevices, setMediaDevices] = useState<MediaDeviceInfo[]>([]);
  useEffect(() => {
    const getDevices = (): void => {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) =>
          devices.every((device) => !(device.deviceId && device.label))
        )
        // eslint-disable-next-line consistent-return
        .then((mediaPermission): Promise<void> | undefined => {
          if (mediaPermission) {
            return navigator.mediaDevices
              .getUserMedia({ audio: true, video: true })
              .then((mediaStream) =>
                mediaStream.getTracks().forEach((track) => track.stop())
              );
          }
        })
        .then(() =>
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            setMediaDevices(devices);
          })
        )
        .catch((error) => {
          AnalyticsService.deviceEnumerationError(
            error,
            DEVICE_ENUMERATION_STACKS.USE_DEVICES
          );
        });
    };

    navigator.mediaDevices.addEventListener("devicechange", getDevices);
    getDevices();

    return (): void => {
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, []);

  return mediaDevices;
}
