import { useEffect, useState } from "react";
import { Participant, RemoteTrackPublication } from "twilio-video";
import { TRACK_PUBLISH_STATE } from "../../constants/events";

function usePublications(participant: Participant): RemoteTrackPublication[] {
  const [publications, setPublications] = useState<RemoteTrackPublication[]>(
    []
  );

  useEffect(() => {
    const { TRACK_PUBLISHED, TRACK_UNPUBLISHED } = TRACK_PUBLISH_STATE;

    setPublications(
      Array.from(participant.tracks.values()) as RemoteTrackPublication[]
    );

    const publicationAdded = (publication: RemoteTrackPublication): void =>
      setPublications((prevPublications) => [...prevPublications, publication]);
    const publicationRemoved = (publication: RemoteTrackPublication): void =>
      setPublications((prevPublications) =>
        prevPublications.filter((p) => p !== publication)
      );

    participant.on(TRACK_PUBLISHED, publicationAdded);
    participant.on(TRACK_UNPUBLISHED, publicationRemoved);
    return (): void => {
      participant.off(TRACK_PUBLISHED, publicationAdded);
      participant.off(TRACK_UNPUBLISHED, publicationRemoved);
    };
  }, [participant]);

  return publications;
}

export function useScreenPublication(
  participant: Participant
): RemoteTrackPublication | undefined {
  const publications = usePublications(participant);

  return publications.find(
    (p) => p.kind === "video" && p.trackName.includes("screen")
  );
}

export function useCameraPublication(
  participant: Participant
): RemoteTrackPublication | undefined {
  const publications = usePublications(participant);

  return publications.find(
    (p) => p.kind === "video" && !p.trackName.includes("screen")
  );
}

export function useAudioPublication(
  participant: Participant
): RemoteTrackPublication | undefined {
  const publications = usePublications(participant);

  return publications.find((p) => p.kind === "audio");
}
