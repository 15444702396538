import React from "react";

import { ReactComponent as ArbolusLogo } from "../../assets/images/logo/logo-tree-blue.svg";

const Header: React.FC = (): JSX.Element => (
  <div className="logo-container">
    <ArbolusLogo />
  </div>
);

export default Header;
