export const RECAPTCHA_SITE_KEY =
  process.env.NX_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY;
export const GOOGLE_TAG_MANAGER_ID =
  process.env.NX_PUBLIC_GOOGLE_TAG_MANAGER_KEY;
export const GOOGLE_TAG_MANAGER_AUTH =
  process.env.NX_PUBLIC_GOOGLE_TAG_MANAGER_AUTH;
export const GOOGLE_TAG_MANAGER_PREVIEW =
  process.env.NX_PUBLIC_GOOGLE_TAG_MANAGER_PREVIEW;
export const INSPECTLET_WEB_ID = process.env.NX_PUBLIC_INSPECTLET_ID;

export const RECAPTCHA_ACTION = "GET_MEETING_TOKEN";

// LocalStorage Keys
export const LOCAL_STORAGE = {
  APP_VERSION: "ciq_connect_version"
};

export const TWILLIO_ERROR_CODES = {
  IDENTITY_DUPLICATE: 53205
};
