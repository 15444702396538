import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { useMediaQuery } from "react-responsive";
import { CIQTooltip } from "../..";
import { APP_DEVICE_MEDIA_QUERIES } from "../../../constants/ui";
import { useModalContext } from "../../../hooks";

const SettingsModalToggle: React.FC = (): JSX.Element => {
  const { toggleSettingsModal } = useModalContext();
  const { t } = useTranslation("twilioRoom");

  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });

  const tooltipPlace = isLargeDevice ? "top" : "bottom";

  return (
    <Button
      className="circle-btn grey no-border"
      id="setting-btn"
      size="lg"
      onClick={(): void => toggleSettingsModal(true)}
      data-tip
      data-for="setting-btn-tool-tip"
    >
      <i className="ciq-icon ciq-call-settings" />
      <CIQTooltip
        id="setting-btn-tool-tip"
        content={t("settings")}
        placement={tooltipPlace}
      />
    </Button>
  );
};

export default SettingsModalToggle;
