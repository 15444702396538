import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { AudioTrack, Participant } from "twilio-video";
import { CIQAvatar } from "../..";
import {
  useCIQParticipantData,
  useIsTrackEnabled,
  useMeetingContext,
  useParticipants,
  useTrack
} from "../../../hooks";
import { useAudioPublication } from "../../../hooks/usePublication/UsePublication";
import { UtilService } from "../../../services";

interface ParticipantItemProps {
  participant: Participant;
  me?: boolean;
}

const ParticipantItem: React.FC<ParticipantItemProps> = ({
  participant,
  me
}: ParticipantItemProps): JSX.Element => {
  const { t } = useTranslation("twilioRoom");
  const ciqParticipantData = useCIQParticipantData(participant.identity);
  const { chooseSelectedParticipant, selectedParticipant } =
    useMeetingContext();

  const participantsCount = useParticipants().length;

  const participantDisplayName = UtilService.displayUserName(
    participant.identity,
    ciqParticipantData
  );

  const audioPublication = useAudioPublication(participant);
  const audioTrack = useTrack(audioPublication) as AudioTrack;
  const isAudioEnabled = useIsTrackEnabled(audioTrack);

  const isSelectedParticipant = selectedParticipant === participant;

  const isLocalParticipantOnly = participantsCount === 0;

  const handlePinClick = (): void => {
    chooseSelectedParticipant(participant);
  };

  return (
    <div className="participant-item">
      <div className="left-container">
        <div className="users-avatar">
          <CIQAvatar
            isDialInParticipant={
              UtilService.isDialInParticipant(participant?.identity) &&
              !ciqParticipantData?.expertFirstName
            }
            username={participantDisplayName || "--"}
            profileImageUrl={ciqParticipantData?.profileImageUrl}
          />
        </div>
        <div className="user-name">
          {me
            ? t("myParticipantName", { participantDisplayName })
            : participantDisplayName || t("connectingUserName")}
        </div>
      </div>
      <div className="right-container">
        <span
          onClick={handlePinClick}
          className={clsx("sidebar-pin-icon", {
            pinned: isSelectedParticipant,
            disabled: isLocalParticipantOnly
          })}
        >
          <i
            className={clsx("ciq-icon", {
              "ciq-push-pin-off": isSelectedParticipant,
              "ciq-push-pin": !isSelectedParticipant
            })}
          />
        </span>
        <span
          className={clsx("volume-icon", {
            pinned: !isAudioEnabled
          })}
        >
          {isAudioEnabled ? (
            <i className="ciq-icon ciq-call-audio-on" />
          ) : (
            <i className="ciq-icon ciq-call-audio-off" />
          )}
        </span>
      </div>
    </div>
  );
};

ParticipantItem.defaultProps = {
  me: false
};

export default ParticipantItem;
