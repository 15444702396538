import { useEffect, useRef, useState } from "react";
import {
  AudioTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  VideoTrack
} from "twilio-video";
import useModalContext from "../useModalContext/useModalContext";

type TrackType =
  | LocalAudioTrack
  | LocalVideoTrack
  | AudioTrack
  | VideoTrack
  | undefined;

export default function useIsTrackEnabled(track: TrackType): boolean {
  const [isEnabled, setIsEnabled] = useState(!!track?.isEnabled);
  const { toggleAutoplayModal } = useModalContext();
  const audioEl = useRef<HTMLAudioElement>();

  useEffect(() => {
    if (track?.kind === "audio") {
      audioEl.current = document.getElementById(
        track?.name
      ) as HTMLAudioElement;
    }
  }, [track]);

  useEffect(() => {
    setIsEnabled(!!track?.isEnabled);

    const setEnabled = (): void => {
      setIsEnabled(true);

      // Autoplay policy - audio paused check
      if (audioEl.current && audioEl.current.paused) {
        toggleAutoplayModal(true);
      }
    };
    const setDisabled = (): void => setIsEnabled(false);

    if (track) {
      // Attach listeners to track events
      track.on("enabled", setEnabled);
      track.on("disabled", setDisabled);
    }

    // Remove on un-mount
    return (): void => {
      track?.off("enabled", setEnabled);
      track?.off("disabled", setDisabled);
    };
  }, [toggleAutoplayModal, track]);

  return isEnabled;
}
