import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody } from "reactstrap";
import { Subscription } from "rxjs";

import { CIQAvatar } from "..";
import { GUEST_APPROVAL_STATE } from "../../constants/meeting";
import {
  JOIN_MODAL_CLOSE_TIME_MILLISECONDS,
  MAX_POLLING_TIME
} from "../../constants/timer";
import { useMeetingContext } from "../../hooks";
import { ErrorResponse } from "../../models/api";
import {
  ExternalJoinStateUpdateRequest,
  GuestUserInfo
} from "../../models/meeting";
import { LegacyMeetingService, NotificationService } from "../../services";

interface JoinRequestModalProps {
  isToggled: boolean;
  joinRequestId: string;
  email: string;
  userInfo: GuestUserInfo;
}

const JoinRequestModal = ({
  isToggled,
  joinRequestId,
  userInfo
}: JoinRequestModalProps): JSX.Element => {
  const { t } = useTranslation("guestAdmitModal");
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const modalTimeoutRef = useRef<any>();
  const [modalCloseTimeout, setModalCloseTimeout] = useState(
    JOIN_MODAL_CLOSE_TIME_MILLISECONDS
  );

  const { twilioToken, markJoinRequestAsTimedOut } = useMeetingContext();
  const joinRequestStateUpdateSubscription = useRef(new Subscription());
  const modalCloseTime = useRef(moment());
  const { name, profileImageUrl } = userInfo;

  useEffect(() => {
    modalCloseTime.current = moment().add(MAX_POLLING_TIME, "seconds");
    return (): void => {
      joinRequestStateUpdateSubscription.current.unsubscribe();
      if (modalTimeoutRef.current) clearTimeout(modalTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    // Initiate or re-initialize timeout on modalCloseTimeout change
    modalTimeoutRef.current = setTimeout(() => {
      markJoinRequestAsTimedOut(joinRequestId);
    }, modalCloseTimeout);
  }, [joinRequestId, markJoinRequestAsTimedOut, modalCloseTimeout]);

  const handleUpdateJoinRequest = (approval: GUEST_APPROVAL_STATE): void => {
    setIsRequestLoading(true);
    const joinUpdateRequest: ExternalJoinStateUpdateRequest = {
      approval,
      joinRequestId,
      meetingId: LegacyMeetingService.getMeetingIdFromURL()!,
      twilioToken: twilioToken!
    };

    clearTimeout(modalTimeoutRef.current!);

    joinRequestStateUpdateSubscription.current =
      LegacyMeetingService.joinRequestStateUpdate(joinUpdateRequest).subscribe(
        () => {
          setIsRequestLoading(false);
        },
        (error: ErrorResponse) => {
          NotificationService.showError(error.message);

          setIsRequestLoading(false);

          if (moment().isBefore(modalCloseTime.current)) {
            // Start timeout for remaining time
            setModalCloseTimeout(
              modalCloseTime.current.diff(moment(), "milliseconds")
            );
          } else {
            markJoinRequestAsTimedOut(joinRequestId);
          }
        }
      );
  };

  const renderAvatarImage = (): JSX.Element => {
    if (userInfo) {
      return <CIQAvatar username={name} profileImageUrl={profileImageUrl} />;
    }

    return <span className="ciq-icon ciq-people" />;
  };

  const modalPortal = document.getElementById("modal-portal");

  return createPortal(
    <Modal
      isOpen={isToggled}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      toggle={(): void => {}}
      className="join-request-modal"
      key={joinRequestId}
    >
      <ModalBody className="join-request-modal-body">
        <div className="join-request-details">
          <div className="avatar">{renderAvatarImage()}</div>
          <div>
            <p title={t("joinToCall")}>{t("joinToCall")}</p>
            <p className="email">{name}</p>
          </div>
        </div>

        <div className="action-buttons">
          <Button
            size="sm"
            color="primary"
            disabled={isRequestLoading}
            onClick={(): void => {
              handleUpdateJoinRequest(GUEST_APPROVAL_STATE.APPROVED);
            }}
          >
            {t("admit")}
          </Button>
          <Button
            size="sm"
            color="secondary"
            disabled={isRequestLoading}
            onClick={(): void => {
              handleUpdateJoinRequest(GUEST_APPROVAL_STATE.DECLINED);
            }}
          >
            {t("deny")}
          </Button>
        </div>
      </ModalBody>
    </Modal>,
    modalPortal!
  );
};

export default JoinRequestModal;
