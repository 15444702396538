import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { AudioTrack, RemoteTrackPublication } from "twilio-video";

import { CIQAvatar } from "../..";
import {
  useAudioLevel,
  useIsTrackEnabled,
  useParticipants,
  useTrack
} from "../../../hooks";
import { CIQParticipant } from "../../../models/meeting";

interface ParticipantPlaceholderProps {
  isDialInParticipant: boolean;
  name: string;
  ciqParticipantData?: CIQParticipant;
  audioPublication?: RemoteTrackPublication;
  isSelected?: boolean;
}

const ParticipantPlaceholder = ({
  name,
  ciqParticipantData,
  audioPublication,
  isSelected,
  isDialInParticipant
}: ParticipantPlaceholderProps): JSX.Element => {
  const { t } = useTranslation("twilioRoom");
  const audioTrack = useTrack(audioPublication);
  const participantsCount = useParticipants().length;
  const isAudioEnabled = useIsTrackEnabled(audioTrack as AudioTrack);
  const { audioLevel } = useAudioLevel(audioTrack as AudioTrack);

  const canBePinned = participantsCount > 0;

  return (
    <div className="meta-container">
      <div className={`middle-container audio-${audioLevel}`}>
        <CIQAvatar
          username={name}
          isDialInParticipant={isDialInParticipant}
          profileImageUrl={ciqParticipantData?.profileImageUrl}
        />
      </div>
      <div className="bottom-container">
        <div
          className={clsx("mic-btn", {
            disabled: !isAudioEnabled
          })}
        >
          <i
            className={clsx("ciq-icon", {
              "ciq-call-audio-on": isAudioEnabled,
              "ciq-call-audio-off": !isAudioEnabled
            })}
          />
        </div>
        <div className="name-container">
          <p>{name || t("connectingUserName")}</p>
        </div>

        {canBePinned && (
          <div
            className={clsx("pin-icon", {
              pinned: isSelected
            })}
          >
            <i
              className={clsx("ciq-icon", {
                "ciq-push-pin-off": isSelected,
                "ciq-push-pin": !isSelected
              })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ParticipantPlaceholder.defaultProps = {
  ciqParticipantData: undefined,
  audioPublication: undefined,
  isSelected: false
};

export default ParticipantPlaceholder;
