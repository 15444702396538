// Max request polling timeout
export const MAX_POLLING_TIME = 120;

// Interval between Join API call
export const POLLING_TIME_INTERVAL = 5000;

// Pusher keep alive interval
export const PUSHER_KEEP_ALIVE_INTERVAL = 5000;

// Join modal close
export const JOIN_MODAL_CLOSE_TIME_MILLISECONDS = 2 * 60 * 1000;

// Poor connection strip reappear timeout
export const POOR_CONNECTION_REAPPEAR_TIMEOUT = 300000;

// Call Status Check Interval
export const CALL_STATUS_CHECK_INTERVAL = 5000;
