import { useDevices } from "..";
import { DEVICE_TYPES } from "../../constants/media";
import { SelectOption } from "../../models/modal";

export function useAudioInputDevices(): SelectOption[] {
  const devices = useDevices();

  return devices
    .filter((device) => device.kind === DEVICE_TYPES.AUDIO_INPUT)
    .map((d) => ({ label: d.label, value: d.deviceId }));
}
