import React from "react";
import { useMeetingContext } from "../../../hooks";
import RecordingIndicatorStatus from "./recordingIndicatorStatus/RecordingIndicatorStatus";

const RecordingIndicator: React.FC = (): JSX.Element => {
  const { isOrganizer } = useMeetingContext();

  if (isOrganizer) {
    return <RecordingIndicatorStatus />;
  }
  return <></>;
};

export default RecordingIndicator;
