import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { CIQTooltip } from "../..";
import { useLocalVideoToggle, useMeetingContext } from "../../../hooks";

const LocalVideoToggle: React.FC = (): JSX.Element => {
  const { isCamRequesting, isConnecting } = useMeetingContext();
  const { isEnabled, toggleVideoVisibility, isCamPublishing } =
    useLocalVideoToggle();
  const { t } = useTranslation("twilioRoom");

  const buttonDisabled = isCamRequesting || isCamPublishing || isConnecting;

  return (
    <Button
      disabled={buttonDisabled}
      size="lg"
      onClick={toggleVideoVisibility}
      className={clsx("circle-btn video-btn", {
        off: !isEnabled,
        outline: isEnabled,
        disabled: buttonDisabled
      })}
      data-tip
      data-for="local-video-toggle-tool-tip"
    >
      {isEnabled ? (
        <i className="ciq-icon ciq-call-video-on" />
      ) : (
        <i className="ciq-icon ciq-call-video-off" />
      )}
      <CIQTooltip
        id="local-video-toggle-tool-tip"
        content={isEnabled ? t("turnOffCamera") : t("turnOnCamera")}
      />
    </Button>
  );
};

export default LocalVideoToggle;
