import { useCallback, useEffect, useState } from "react";
import { Participant } from "twilio-video";
import { PARTICIPANT_STATE } from "../../constants/events";
import { NotJoinedParticipant } from "../../models/view/participants";
import { UtilService } from "../../services";
import useMeetingContext from "../useMeetingContext/UseMeetingContext";
import useParticipants from "../useParticipants/UseParticipants";

const { PARTICIPANT_CONNECTED, PARTICIPANT_DISCONNECTED } = PARTICIPANT_STATE;

export default function useNotJoinedParticipants(): {
  notJoined: NotJoinedParticipant[];
  addNew: (dialedParticipant: NotJoinedParticipant) => void;
  recallExisting: (dialedParticipant: NotJoinedParticipant) => void;
  remove: (dialedParticipant: NotJoinedParticipant) => void;
} {
  const twilioParticipants = useParticipants();
  const { room, meeting } = useMeetingContext();

  const { participants } = meeting!;
  const { localParticipant } = room;

  const [notJoined, setNotJoined] = useState<NotJoinedParticipant[]>(
    participants
      ?.filter(({ email, isExpert, phoneNumber }) => {
        // Should not be local participant
        if (email === localParticipant.identity) {
          return false;
        }
        // List only experts who has phone number
        if (!isExpert || !phoneNumber) {
          return false;
        }

        const alreadyJoined = twilioParticipants.find(
          ({ identity }) => identity === email || identity === phoneNumber
        );
        if (alreadyJoined) {
          return false;
        }

        return true;
      })
      .map((mp) => ({
        phoneNumber: mp.phoneNumber!,
        participant: mp
      })) || []
  );

  const addNew = useCallback(
    (dialedParticipant: NotJoinedParticipant): void => {
      setNotJoined((prevState) => [...prevState, dialedParticipant]);
    },
    []
  );

  const recallExisting = useCallback(
    (dialedParticipant: NotJoinedParticipant): void => {
      setNotJoined((prev) =>
        prev.map((nj) => {
          if (nj.phoneNumber === dialedParticipant.phoneNumber) {
            return dialedParticipant;
          }

          return nj;
        })
      );
    },
    []
  );

  const remove = useCallback(
    (dialedParticipant: NotJoinedParticipant): void => {
      if (!dialedParticipant.participant?.email) {
        setNotJoined((prevState) =>
          prevState.filter(
            (p) => p.phoneNumber !== dialedParticipant.phoneNumber
          )
        );
      }
    },
    []
  );

  useEffect(() => {
    const handleParticipantConnected = ({ identity }: Participant): void => {
      setNotJoined((prevState) =>
        prevState.filter(
          (i) => i.phoneNumber !== identity && i.participant?.email !== identity
        )
      );
    };

    const handleParticipantDisconnected = ({ identity }: Participant): void => {
      const isDialInParticipant = UtilService.isDialInParticipant(identity);

      const disconnectParticipant = participants?.find(
        ({ phoneNumber, email, isExpert }) => {
          // Re-list only experts who has phone number
          if (!isExpert || !phoneNumber) return false;

          return identity === email || identity === phoneNumber;
        }
      );

      if (disconnectParticipant) {
        let isCompletelyLeft = true;

        if (isDialInParticipant) {
          isCompletelyLeft =
            twilioParticipants.findIndex(
              (tp) => tp.identity === disconnectParticipant.email
            ) === -1;
        } else {
          isCompletelyLeft =
            twilioParticipants.findIndex(
              (tp) => tp.identity === disconnectParticipant.phoneNumber
            ) === -1;
        }

        if (isCompletelyLeft) {
          const newNotJoinedParticipant: NotJoinedParticipant = {
            phoneNumber: disconnectParticipant.phoneNumber!,
            participant: disconnectParticipant
          };

          setNotJoined((prevState) => [...prevState, newNotJoinedParticipant]);
        }
      }
    };
    room.on(PARTICIPANT_CONNECTED, handleParticipantConnected);
    room.on(PARTICIPANT_DISCONNECTED, handleParticipantDisconnected);

    return (): void => {
      room.off(PARTICIPANT_CONNECTED, handleParticipantConnected);
      room.off(PARTICIPANT_DISCONNECTED, handleParticipantDisconnected);
    };
  }, [meeting, participants, room, twilioParticipants]);

  return {
    notJoined,
    addNew,
    recallExisting,
    remove
  };
}
