import i18next from "i18next";
import { useCallback, useState } from "react";
import { ModalContent } from "../../../models/modal";

export enum PermissionModalEvents {
  CAM_AND_MIC_BLOCKED = "CAM_AND_MIC_BLOCKED",
  CAM_BLOCKED = "CAM_BLOCKED",
  MIC_BLOCKED = "MIC_BLOCKED",
  CAM_NOT_FOUND = "CAM_NOT_FOUND"
}

const MODAL_CONTENTS = {
  [`${PermissionModalEvents.CAM_AND_MIC_BLOCKED}`]: {
    title: i18next.t("permissionModal:camMicBlocked")
  } as ModalContent,

  [`${PermissionModalEvents.CAM_BLOCKED}`]: {
    icon: "ciq-call-video-off",
    title: i18next.t("permissionModal:camBlocked")
  } as ModalContent,

  [`${PermissionModalEvents.MIC_BLOCKED}`]: {
    icon: "ciq-call-audio-off",
    title: i18next.t("permissionModal:micBlocked")
  } as ModalContent,

  [`${PermissionModalEvents.CAM_NOT_FOUND}`]: {
    icon: "ciq-call-video-off",
    title: i18next.t("permissionModal:noCamFound"),
    message: i18next.t("permissionModal:noCamFoundMessage")
  } as ModalContent
};

interface UsePermissionModal {
  isPermissionModalToggle: boolean;
  togglePermissionModal: (
    toggled: boolean,
    event?: PermissionModalEvents
  ) => void;
  permissionModalContent: ModalContent;
  isSettingsModalToggle: boolean;
  toggleSettingsModal: (toggled: boolean) => void;
}

const usePermissionModal = (): UsePermissionModal => {
  const [isPermissionModalToggle, setIsPermissionModalToggle] = useState(false);
  const [permissionModalContent, setPermissionModalContent] = useState({
    title: ""
  });
  const [isSettingsModalToggle, setIsSettingsModalToggle] = useState(false);

  const togglePermissionModal = useCallback(
    (toggled: boolean, event?: PermissionModalEvents) => {
      setIsPermissionModalToggle(toggled);
      if (event) setPermissionModalContent(MODAL_CONTENTS[event]);
    },
    []
  );

  const toggleSettingsModal = useCallback((toggled: boolean) => {
    setIsSettingsModalToggle(toggled);
  }, []);

  return {
    isPermissionModalToggle,
    togglePermissionModal,
    permissionModalContent,
    toggleSettingsModal,
    isSettingsModalToggle
  };
};

export default usePermissionModal;
