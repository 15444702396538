import { useEffect, useState } from "react";
import {
  LocalAudioTrack,
  LocalDataTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteDataTrack,
  RemoteTrackPublication,
  RemoteVideoTrack
} from "twilio-video";
import { PUBLICATION_STATE } from "../../constants/events";

export default function useTrack(
  publication: LocalTrackPublication | RemoteTrackPublication | undefined
):
  | LocalAudioTrack
  | LocalVideoTrack
  | LocalDataTrack
  | RemoteAudioTrack
  | RemoteVideoTrack
  | RemoteDataTrack
  | null
  | undefined {
  const [track, setTrack] = useState(publication && publication.track);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { SUBSCRIBED, UNSUBSCRIBED } = PUBLICATION_STATE;
    setTrack(publication && publication.track);

    if (publication) {
      const removeTrack = (): void => setTrack(null);

      publication.on(SUBSCRIBED, setTrack);
      publication.on(UNSUBSCRIBED, removeTrack);
      return (): void => {
        publication.off(SUBSCRIBED, setTrack);
        publication.off(UNSUBSCRIBED, removeTrack);
      };
    }
  }, [publication]);

  return track;
}
