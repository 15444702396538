import { createContext } from "react";
import { PermissionModalEvents } from "../hooks/modalContext/usePermissionModal/UsePermissionModal";
import { ModalContent } from "../models/modal";

export interface ModalContextState {
  isPermissionModalToggle: boolean;
  togglePermissionModal: (
    toggled: boolean,
    event?: PermissionModalEvents
  ) => void;
  permissionModalContent: ModalContent;
  isSettingsModalToggle: boolean;
  toggleSettingsModal: (toggled: boolean) => void;

  selectedAudioDeviceId?: string;
  setSelectedAudioDevice: (deviceId?: string) => void;

  selectedVideoDeviceId?: string;
  setSelectedVideoDevice: (deviceId?: string) => void;

  isAutoplayModalToggle: boolean;
  toggleAutoplayModal: (toggled: boolean) => void;
  playAudioElementCallback?: () => void;
}

export const ModalContext = createContext<ModalContextState>(null!);

export const ModalContextProvider = ModalContext.Provider;
export const ModalContextConsumer = ModalContext.Consumer;
