import { CIQParticipant } from "../meeting";

export interface NotJoinedParticipant {
  phoneNumber: string;
  participant?: CIQParticipant;
  fromTab?: boolean;
}

export enum DialInStatus {
  IDLE = "idle", // UI Only
  QUEUED = "queued",
  INITIATED = "initiated",
  RINGING = "ringing",
  IN_PROGRESS = "in-progress",
  BUSY = "busy",
  NO_ANSWER = "no-answer",
  FAILED = "failed",
  CANCELED = "canceled",
  COMPLETED = "completed"
}
