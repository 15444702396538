import { useEffect, useState } from "react";
import { LocalVideoTrack, RemoteVideoTrack } from "twilio-video";

type TrackType = RemoteVideoTrack | LocalVideoTrack | undefined | null;

// The 'switchedOff' event is emitted when there is not enough bandwidth to support

export default function useIsTrackSwitchedOff(track: TrackType): boolean {
  const [isSwitchedOff, setIsSwitchedOff] = useState(
    track && track.isSwitchedOff
  );

  useEffect(() => {
    // Reset the value if the 'track' variable changes
    setIsSwitchedOff(track && track.isSwitchedOff);

    const handleSwitchedOff = (): void => setIsSwitchedOff(true);
    const handleSwitchedOn = (): void => setIsSwitchedOff(false);

    track?.on("switchedOff", handleSwitchedOff);
    track?.on("switchedOn", handleSwitchedOn);

    return (): void => {
      track?.off("switchedOff", handleSwitchedOff);
      track?.off("switchedOn", handleSwitchedOn);
    };
  }, [track]);

  return !!isSwitchedOff;
}
