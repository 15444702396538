import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import { CIQSelect } from "../..";

import { useAudioOutputDevices, useMeetingContext } from "../../../hooks";

export function AudioOutputDevices(): JSX.Element | null {
  const { t } = useTranslation("settingsModal");
  const { sinkId, setSinkId } = useMeetingContext();
  const audioOutputDevices = useAudioOutputDevices();

  let selectedDeviceOption;

  function updateTrack(deviceId: string): void {
    setSinkId(deviceId);
  }

  const hasDevices = audioOutputDevices.length > 0;

  if (hasDevices) {
    selectedDeviceOption = audioOutputDevices.find(
      (d) => d.value === (sinkId || "default")
    );
  }

  return hasDevices ? (
    <div className="audio-output-list">
      <Label>{t("speakers")}</Label>
      <CIQSelect
        defaultValue={selectedDeviceOption}
        options={audioOutputDevices}
        onSelectChange={(value: string): void => updateTrack(value)}
        noOptionsMessage={t("noOptions")}
        customStyle
      />
    </div>
  ) : (
    <></>
  );
}
