import React, { useEffect } from "react";
import { isMobile, isTablet } from "react-device-detect";
import ReactTooltip, { Place } from "react-tooltip";

interface CustomTooltipProps {
  id: string;
  content: string;
  placement?: Place;
  customClass?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  id,
  content,
  placement,
  customClass
}: CustomTooltipProps) => {
  const isMobileOrTablet = isMobile || isTablet;

  useEffect(() => {
    // rebind the tooltip for a new element
    ReactTooltip.rebuild();
  });

  return (
    <ReactTooltip
      place={placement}
      id={id}
      effect="solid"
      arrowColor="transparent"
      event="mouseenter"
      eventOff="mouseleave click"
      globalEventOff="click"
      disable={isMobileOrTablet}
      className={customClass}
    >
      {content}
    </ReactTooltip>
  );
};

CustomTooltip.defaultProps = {
  placement: "top",
  customClass: undefined
};

export default CustomTooltip;
