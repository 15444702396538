import React from "react";
import { useTranslation } from "react-i18next";

const NotFound: React.FC = (): JSX.Element => {
  const { t } = useTranslation("notFound");
  return (
    <div className="not-found-container">
      <h2>404</h2>
      <p>{t("notFoundMessage")}</p>
    </div>
  );
};

export default NotFound;
