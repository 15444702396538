import React from "react";
import {
  AudioTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  RemoteTrackPublication,
  RemoteVideoTrack
} from "twilio-video";
import { useTrack } from "../../../hooks";
import { CIQParticipant } from "../../../models/meeting";
import ParticipantVideoPreview from "../participantVideoPreview/ParticipantVideoPreview";

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  audioPublication?: RemoteTrackPublication;
  isLocalParticipant: boolean;
  name: string;
  isDialInParticipant: boolean;
  ciqParticipantData?: CIQParticipant;
  isSelected?: boolean;
}

export default function VideoPublication({
  publication,
  audioPublication,
  isLocalParticipant,
  name,
  ciqParticipantData,
  isSelected,
  isDialInParticipant
}: PublicationProps): JSX.Element | null {
  const track = useTrack(publication);
  const audioTrack = useTrack(audioPublication);

  if (!track) return null;

  return (
    <ParticipantVideoPreview
      isDialInParticipant={isDialInParticipant}
      audioTrack={audioTrack as AudioTrack}
      isLocalParticipant={isLocalParticipant}
      name={name}
      ciqParticipantData={ciqParticipantData}
      track={track as RemoteVideoTrack | LocalVideoTrack}
      isSelected={isSelected}
    />
  );
}

VideoPublication.defaultProps = {
  ciqParticipantData: undefined,
  audioPublication: undefined,
  isSelected: false
};
