import React from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useModalContext } from "../../hooks";

const AutoplayPolicyModal = (): JSX.Element => {
  const {
    isAutoplayModalToggle,
    toggleAutoplayModal,
    playAudioElementCallback
  } = useModalContext();
  const { t } = useTranslation("autoplayPolicyModal");

  const modalPortal = document.getElementById("modal-portal");

  return createPortal(
    <Modal
      isOpen={isAutoplayModalToggle}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      toggle={(): void => {}}
      className="autoplay-modal"
    >
      <ModalBody>
        <div className="microphone-icon">
          <span className="ciq-icon ciq-call-audio-on" />
        </div>
        <div className="interactive-message">
          <p className="title">{t("title")}</p>
          <p>{t("message")}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          size="sm"
          color="primary"
          className="footer-btn"
          onClick={(): void => {
            toggleAutoplayModal(false);
            if (playAudioElementCallback) {
              playAudioElementCallback();
            }
          }}
        >
          {t("buttonText")}
        </Button>
      </ModalFooter>
    </Modal>,
    modalPortal!
  );
};

export default AutoplayPolicyModal;
