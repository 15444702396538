// eslint-disable-next-line no-useless-escape
export const MEETING_ID_REGEX =
  /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/i;
export const MEETING_PIN_FORMAT_REGEX = /\B(?=(\d{3})+(?!\d))/;

export const UserConstraints = {
  MAX_EMAIL_LENGTH: 128,
  MAX_NAME_LENGTH: 100
};

export const EventConstraints = {
  EXPIRE_FILE_DURATION: 1 // Attachment link will expired
};
