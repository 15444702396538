import { useEffect, useState } from "react";
import { NetworkQualityLevel, NetworkQualityStats } from "twilio-video";
import { PARTICIPANT_STATE } from "../../constants/events";
import { BAD_NETWORK_QUALITY_THRESHOLD } from "../../constants/meeting";
import { AnalyticsService } from "../../services";
import useMeetingContext from "../useMeetingContext/UseMeetingContext";

export function useLocalNetworkQuality(): number | undefined {
  const { room } = useMeetingContext();
  const [participantNetworkQualityLevel, setParticipantNetworkQualityLevel] =
    useState<number>();

  useEffect(() => {
    const participantNetworkQualityChange = (
      networkQualityLevel: NetworkQualityLevel,
      networkQualityStats: NetworkQualityStats
    ): void => {
      setParticipantNetworkQualityLevel(networkQualityLevel);

      if (networkQualityLevel <= BAD_NETWORK_QUALITY_THRESHOLD) {
        AnalyticsService.poorNetworkQuality(networkQualityStats);
      }
    };

    room.localParticipant.on(
      PARTICIPANT_STATE.PARTICIPANT_NETWORK_QUALITY,
      participantNetworkQualityChange
    );

    return (): void => {
      room.localParticipant.off(
        PARTICIPANT_STATE.PARTICIPANT_NETWORK_QUALITY,
        participantNetworkQualityChange
      );
    };
  });

  return participantNetworkQualityLevel;
}
