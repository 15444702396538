import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import SimpleBar from "simplebar-react";

import PhoneInput from "react-phone-input-2";
import { Button, FormGroup, Label } from "reactstrap";
import { ErrorResponse } from "../../../models/api";

import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_DIAL_IN_TAB
} from "../../../constants/ui";
import { useParticipants } from "../../../hooks";
import { CIQParticipant } from "../../../models/meeting";
import { NotJoinedParticipant } from "../../../models/view/participants";
import { UtilService } from "../../../services";
import AlertView from "../../alert/AlertView";
import { dialInSchema } from "./DialInSchema";

type DialInFormValues = {
  phoneNumber: string;
};

interface DialInTabProps {
  dialInNumbers?: object;
  notJoinedParticipants: NotJoinedParticipant[];
  onNavigateBack: () => void;
  onRedial: (notJoined: NotJoinedParticipant) => void;
  onNewDialIn: (notJoined: NotJoinedParticipant) => void;
}

const DialInTab = ({
  onNavigateBack,
  dialInNumbers,
  notJoinedParticipants,
  onRedial,
  onNewDialIn
}: DialInTabProps): JSX.Element => {
  const [dialInError, setDialInError] = useState<ErrorResponse | undefined>(
    undefined
  );
  const { t } = useTranslation("twilioRoom");
  const participants = useParticipants();

  const { errors, handleSubmit, control, reset, formState } =
    useForm<DialInFormValues>({
      defaultValues: {
        phoneNumber: ""
      },
      mode: "all",
      reValidateMode: "onSubmit",
      resolver: yupResolver(
        dialInSchema(
          Object.values(dialInNumbers!).map((d) => d.replaceAll(" ", ""))
        )
      )
    });

  const disableDialIn = !formState.isValid;

  const handleNavigateBack = (): void => {
    reset();
    setDialInError(undefined);
    onNavigateBack();
  };

  const isSmallerDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.small
  });

  const handleFormSubmit = handleSubmit(({ phoneNumber }) => {
    const intlNumber = `+${phoneNumber}`;

    // Check participant already on call
    const participantExist = participants.findIndex(
      ({ identity }) =>
        UtilService.isDialInParticipant(identity) && identity === intlNumber
    );
    if (participantExist !== -1) {
      setDialInError({
        message: t("alreadyJoined")
      } as ErrorResponse);
      return;
    }

    // Dial-in existing/new not joined experts
    const isRedial = notJoinedParticipants.find(
      (nj) => nj.phoneNumber === intlNumber
    );
    if (isRedial) {
      // Update not joined participant
      onRedial({
        ...isRedial,
        fromTab: true
      });
    } else {
      // Add new not joined participant
      onNewDialIn({
        phoneNumber: intlNumber,
        fromTab: true,
        participant: { phoneNumber: intlNumber } as CIQParticipant
      });
    }

    handleNavigateBack();
  });

  return (
    <div className="dial-direct-sidebar-body">
      <div className="sidebar-header">
        <span
          className="ciq-icon ciq-icon-ic-up"
          onClick={handleNavigateBack}
        />
        <span className="option-link" onClick={handleNavigateBack}>
          {t("cancel")}
        </span>
      </div>
      <hr />
      <SimpleBar
        style={{
          maxHeight: UI_DIAL_IN_TAB.DIAL_IN_CONTAINER_HEIGHT(isSmallerDevice),
          height: UI_DIAL_IN_TAB.DIAL_IN_CONTAINER_HEIGHT(isSmallerDevice)
        }}
        className="dial-direct-container simplebar-light"
      >
        <div className="ciq-icon ciq-icon-ic-on-phone-dial-in" />
        <h2>{t("dialSomeone")}</h2>
        <p>{t("dialSomeoneInfo")}</p>

        <div className="dial-form w-100">
          {dialInError && <AlertView feedback={dialInError} />}
          <form onSubmit={handleFormSubmit}>
            <FormGroup className={clsx({ "is-invalid": errors.phoneNumber })}>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ name, onBlur, onChange, value }): JSX.Element => (
                  <PhoneInput
                    inputProps={{
                      name,
                      autoComplete: "off"
                    }}
                    placeholder={t("phonePlaceholder")}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    specialLabel=""
                  />
                )}
              />

              {errors.phoneNumber && (
                <Label className="invalid-feedback">
                  {errors.phoneNumber.message}
                </Label>
              )}
            </FormGroup>
            <Button
              type="submit"
              color="primary"
              size="lg"
              disabled={disableDialIn}
              className="shadow-none"
            >
              <p>{t("dialIn")}</p>
            </Button>
          </form>
        </div>
      </SimpleBar>
    </div>
  );
};

DialInTab.defaultProps = {
  dialInNumbers: {}
};

export default DialInTab;
