import React from "react";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { Col, Row } from "reactstrap";

import { useMeetingContext } from "../../../hooks";
import LocalAudioToggle from "../../lobby/localAudioToggle/LocalAudioToggle";
import LocalVideoToggle from "../../lobby/localVideoToggle/LocalVideoToggle";

import { APP_DEVICE_MEDIA_QUERIES } from "../../../constants/ui";
import { ParticipantSidebarButton } from "../../participantSideBar/ParticipantSidebarToggle";
import SettingsModalToggle from "../../settingsModal/settingsToggle/SettingsModalToggle";
import { DisconnectButton } from "../disconnect/DisconnectButton";
import { ShareScreenToggle } from "../shareScreen/ShareScreenToggle";

const BottomActionBar: React.FC = (): JSX.Element => {
  const { meeting } = useMeetingContext();
  const { title } = meeting!;
  const {
    toggleParticipantSidebar,
    toggleEventSidebar,
    isParticipantSidebarOpen
  } = useMeetingContext();

  const handleTitleClicked = (): void => {
    if (isParticipantSidebarOpen) toggleParticipantSidebar();

    toggleEventSidebar();
  };

  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });

  return (
    <div className="footer-container">
      <Row>
        <Col
          xs={12}
          sm={12}
          md={4}
          className="title-container"
          onClick={handleTitleClicked}
        >
          <h4>{title}</h4>
        </Col>
        <Col xs={12} sm={12} md={4} className="btn-group-container">
          <LocalAudioToggle />
          <LocalVideoToggle />
          {!isMobile && <ShareScreenToggle />}
          {!isLargeDevice && <ParticipantSidebarButton />}
          <DisconnectButton />
        </Col>
        {isLargeDevice && (
          <Col className="btn-right-container" xs={2} sm={4} md={4}>
            <div className="right-container">
              <ParticipantSidebarButton />
            </div>
            <SettingsModalToggle />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default BottomActionBar;
