import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Participant, TrackPublication } from "twilio-video";
import { useMeetingContext } from "..";
import {
  PARTICIPANT_STATE,
  ROOM_STATE_TYPE,
  TRACK_PUBLISH_STATE
} from "../../constants/events";
import { NotificationService, UtilService } from "../../services";
import { usePrevious } from "../usePrevious/UsePrevious";

export function useScreenShareParticipant(): Participant | undefined {
  const { room, meeting } = useMeetingContext();
  const { t } = useTranslation("twilioRoom");

  const [screenShareParticipant, setScreenShareParticipant] =
    useState<Participant>();

  const prevScreenShareParticipant = usePrevious(screenShareParticipant);

  const participantDataMap = UtilService.createParticipantDataMap(
    meeting?.participants || []
  );

  useEffect(() => {
    const updateScreenShareParticipant = (): void => {
      setScreenShareParticipant(
        Array.from<Participant>(room.participants.values())
          .concat(room.localParticipant)
          .find((participant: Participant) =>
            Array.from<TrackPublication>(participant.tracks.values()).find(
              (track) => track.trackName.includes("screen")
            )
          )
      );
    };
    if (room.state === ROOM_STATE_TYPE.CONNECTED) {
      updateScreenShareParticipant();

      room.on(
        TRACK_PUBLISH_STATE.TRACK_PUBLISHED,
        updateScreenShareParticipant
      );
      room.on(
        TRACK_PUBLISH_STATE.TRACK_UNPUBLISHED,
        updateScreenShareParticipant
      );
      room.on(
        PARTICIPANT_STATE.PARTICIPANT_DISCONNECTED,
        updateScreenShareParticipant
      );

      room.localParticipant.on(
        TRACK_PUBLISH_STATE.TRACK_PUBLISHED,
        updateScreenShareParticipant
      );
      room.localParticipant.on(
        TRACK_PUBLISH_STATE.TRACK_UNPUBLISHED,
        updateScreenShareParticipant
      );
    }
    return (): void => {
      room.off(
        TRACK_PUBLISH_STATE.TRACK_PUBLISHED,
        updateScreenShareParticipant
      );
      room.off(
        TRACK_PUBLISH_STATE.TRACK_UNPUBLISHED,
        updateScreenShareParticipant
      );
      room.off(
        PARTICIPANT_STATE.PARTICIPANT_DISCONNECTED,
        updateScreenShareParticipant
      );

      room.localParticipant.off(
        TRACK_PUBLISH_STATE.TRACK_PUBLISHED,
        updateScreenShareParticipant
      );
      room.localParticipant.off(
        TRACK_PUBLISH_STATE.TRACK_UNPUBLISHED,
        updateScreenShareParticipant
      );
    };
  }, [room]);

  useEffect(() => {
    const getParticipantName = (sIdentity: string): string | undefined => {
      const participantData = participantDataMap.get(sIdentity);
      const name = UtilService.displayUserName(sIdentity, participantData);

      return name;
    };

    if (prevScreenShareParticipant !== screenShareParticipant) {
      const isIamSharing =
        screenShareParticipant?.identity === room.localParticipant.identity;

      if (screenShareParticipant) {
        if (isIamSharing) {
          NotificationService.showInfo(t("youPresenting"));
        } else {
          const pName = getParticipantName(screenShareParticipant.identity);
          if (pName)
            NotificationService.showInfo(t("someonePresenting", { pName }));
        }
      }
    }
  }, [
    t,
    prevScreenShareParticipant,
    screenShareParticipant,
    room,
    participantDataMap
  ]);

  return screenShareParticipant;
}
