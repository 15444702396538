import clsx from "clsx";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Col } from "reactstrap";
import { Participant } from "twilio-video";

import {
  useCIQParticipantData,
  useGridVideoDimensions,
  useMeetingContext,
  useParticipants,
  useStackMode
} from "../../../hooks";

import { UtilService } from "../../../services";

import { APP_DEVICE_MEDIA_QUERIES, UI_ROOM } from "../../../constants/ui";
import {
  useAudioPublication,
  useCameraPublication,
  useScreenPublication
} from "../../../hooks/usePublication/UsePublication";
import ParticipantPlaceholder from "../participantPlaceholder/ParticipantPlaceholder";
import LocalScreenShareCard from "../screenShareCard/LocalScreenShareCard";

import { CIQLoader } from "../..";
import { STACK_MODE } from "../../../constants/meeting";
import useParticipantReconnecting from "../../../hooks/useParticipantReconnect/useParticipantReconnect";
import AudioPublication from "../publication/AudioPublication";
import VideoPublication from "../publication/VideoPublication";

interface ParticipantCardProps {
  participant: Participant;
  isLocalParticipant: boolean;
  isMainParticipant?: boolean;
  hideParticipant: boolean;
}

const ParticipantCard: React.FC<ParticipantCardProps> = ({
  participant,
  isLocalParticipant,
  isMainParticipant,
  hideParticipant
}) => {
  const audioPublication = useAudioPublication(participant);
  const cameraPublication = useCameraPublication(participant);
  const screenSharePublication = useScreenPublication(participant);
  const isReconnecting = useParticipantReconnecting(participant);
  const participantsCount = useParticipants().length;

  // Added max listeners count
  participant.setMaxListeners(20 * participantsCount);

  const { identity } = participant;

  const { rowCount, colSize } = useGridVideoDimensions();

  const ciqParticipantData = useCIQParticipantData(identity);

  const { selectedParticipant, chooseSelectedParticipant } =
    useMeetingContext();

  const stackMode = useStackMode();
  const { HORIZONTAL, VERTICAL } = STACK_MODE;

  const isDialInParticipant = UtilService.isDialInParticipant(identity);

  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });

  const isOwnScreenShare = isLocalParticipant && screenSharePublication;
  const name = UtilService.displayUserName(identity, ciqParticipantData);

  const isNoVideo = !(cameraPublication || screenSharePublication);

  const isLocalParticipantOnly = participantsCount === 0;

  const handleCardClick = (): void => {
    if (!isLocalParticipantOnly) {
      chooseSelectedParticipant(participant);
    }
  };

  // Calculation for main participant data
  let excessHeight = UI_ROOM.ROOM_EXCESS_HEIGHT;
  let heightStyle = `calc(((var(--vh) * 100) - ${excessHeight}px) / ${rowCount})`;

  // Calculations for presentation view
  let columnSize = colSize;

  if (isMainParticipant) {
    columnSize = 12;

    if (
      stackMode === HORIZONTAL &&
      !selectedParticipant &&
      !isLocalParticipantOnly
    ) {
      excessHeight += UI_ROOM.PARTICIPANT_LIST_HEIGHT(isLargeDevice);
    }

    heightStyle = `calc(((var(--vh) * 100) - ${excessHeight}px)`;
  } else if (stackMode === HORIZONTAL) {
    columnSize = isLargeDevice ? 3 : 6;
    heightStyle = `${UI_ROOM.PARTICIPANT_LIST_HEIGHT(isLargeDevice)}px`;
  } else if (stackMode === VERTICAL) {
    columnSize = 12;
    heightStyle = `${UI_ROOM.PARTICIPANT_LIST_HEIGHT(isLargeDevice)}px`;
  }

  return (
    <Col
      xs={columnSize}
      className={clsx({
        hidden: hideParticipant
      })}
    >
      <div
        className={clsx("participant-card", {
          "video-on": !!cameraPublication,
          "screen-on": !!screenSharePublication
        })}
        style={{
          height: heightStyle,
          minHeight: heightStyle,
          maxHeight: heightStyle
        }}
        onClick={handleCardClick}
      >
        {/* Show loader while reconnecting/resolve name */}
        {(isReconnecting || !name) && (
          <CIQLoader
            isFull
            loaderColor="dark"
            isReconnect={isReconnecting}
            isConnecting={!isReconnecting && !name}
          />
        )}
        {/* Show scree share placeholder for own screen share */}
        {isOwnScreenShare && (
          <LocalScreenShareCard
            name={name}
            audioPublication={audioPublication}
          />
        )}

        {/* If not video views  show placeholder */}
        {isNoVideo && (
          <ParticipantPlaceholder
            isDialInParticipant={isDialInParticipant}
            name={name}
            audioPublication={audioPublication}
            ciqParticipantData={ciqParticipantData}
            isSelected={participant === selectedParticipant}
          />
        )}

        {/* Display camera preview if no screen sharing */}
        {!screenSharePublication && cameraPublication && (
          <VideoPublication
            isDialInParticipant={isDialInParticipant}
            audioPublication={audioPublication}
            publication={cameraPublication}
            isLocalParticipant={isLocalParticipant}
            name={name}
            ciqParticipantData={ciqParticipantData}
            isSelected={participant === selectedParticipant}
          />
        )}

        {/* Display screen sharing of other participants */}
        {!isOwnScreenShare && screenSharePublication && (
          <VideoPublication
            isDialInParticipant={isDialInParticipant}
            publication={screenSharePublication}
            isLocalParticipant={isLocalParticipant}
            name={name}
            ciqParticipantData={ciqParticipantData}
            isSelected={participant === selectedParticipant}
          />
        )}

        {audioPublication && !isLocalParticipant && !isMainParticipant && (
          <AudioPublication publication={audioPublication} />
        )}
      </div>
    </Col>
  );
};

export default ParticipantCard;
