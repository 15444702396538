import { useEffect, useState } from "react";
import { Participant } from "twilio-video";
import { PARTICIPANT_STATE } from "../../constants/events";

export default function useParticipantReconnecting(
  participant: Participant
): boolean {
  const [isReconnecting, setIsReconnecting] = useState(false);

  useEffect(() => {
    const handleReconnecting = (): void => setIsReconnecting(true);
    const handleReconnected = (): void => setIsReconnecting(false);

    handleReconnected();

    participant.on(
      PARTICIPANT_STATE.PARTICIPANT_RECONNECTING,
      handleReconnecting
    );
    participant.on(
      PARTICIPANT_STATE.PARTICIPANT_RECONNECTED,
      handleReconnected
    );
    return (): void => {
      participant.off(
        PARTICIPANT_STATE.PARTICIPANT_RECONNECTING,
        handleReconnecting
      );
      participant.off(
        PARTICIPANT_STATE.PARTICIPANT_RECONNECTED,
        handleReconnected
      );
    };
  }, [participant]);

  return isReconnecting;
}
