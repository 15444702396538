import { ConfigProvider } from "antd";
import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import "material-symbols";

import "./i18n";
import "./custom";
import { CIQLoader } from "./app/components";
import { LOCAL_STORAGE } from "./app/constants/app";

import antTheme from "../../../light.json";
import packageJson from "../package.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "flag-icon-css/css/flag-icon.min.css";
import "./App.scss";
import "./app/assets/scss/styles.scss";
import "./app/assets/fonts/style.css";
import "simplebar/dist/simplebar.min.css";
import "intl-tel-input/build/css/intlTelInput.css";
import "./index.css";

const App = lazy(() => import("./App"));

const currentVersion = packageJson.version;

const lastAppVersion =
  localStorage.getItem(LOCAL_STORAGE.APP_VERSION) ?? currentVersion;

if (lastAppVersion === currentVersion) {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(
    <ConfigProvider theme={antTheme}>
      <Suspense fallback={<CIQLoader isFull loaderColor="dark" />}>
        <App />
      </Suspense>
    </ConfigProvider>
  );
  localStorage.setItem(LOCAL_STORAGE.APP_VERSION, currentVersion);
} else {
  localStorage.setItem(LOCAL_STORAGE.APP_VERSION, currentVersion);
  window.location.reload();
}
