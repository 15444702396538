import React, { useState } from "react";

import { AutoplayPolicyModal, PermissionModal } from "..";
import { ModalContextProvider } from "../../contexts/ModalContext";
import { useAutoplayModal, usePermissionModal } from "../../hooks";

const ModalProvider = ({ children }: { children: any }) => {
  const {
    isPermissionModalToggle,
    togglePermissionModal,
    permissionModalContent,
    isSettingsModalToggle,
    toggleSettingsModal
  } = usePermissionModal();

  const {
    isAutoplayModalToggle,
    toggleAutoplayModal,
    playAudioElementCallback
  } = useAutoplayModal();

  const [selectedAudioDeviceId, setSelectedAudioDevice] = useState<string>();
  const [selectedVideoDeviceId, setSelectedVideoDevice] = useState<string>();

  return (
    <ModalContextProvider
      value={{
        isPermissionModalToggle,
        togglePermissionModal,
        permissionModalContent,
        isSettingsModalToggle,
        toggleSettingsModal,
        selectedAudioDeviceId,
        setSelectedAudioDevice,
        selectedVideoDeviceId,
        setSelectedVideoDevice,

        isAutoplayModalToggle,
        toggleAutoplayModal,
        playAudioElementCallback
      }}
    >
      <PermissionModal />
      <AutoplayPolicyModal />
      {children}
    </ModalContextProvider>
  );
};

export default ModalProvider;
