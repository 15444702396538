export const AUDIO_PUBLISH_STACKS = {
  USE_ROOM: "USE ROOM HOOK",
  AUDIO_TOGGLE: "USE AUDIO TOGGLE HOOK"
};

export const VIDEO_PUBLISH_STACKS = {
  USE_ROOM: "USE ROOM HOOK",
  VIDEO_TOGGLE: "USE VIDEO TOGGLE HOOK"
};

export const DEVICE_ENUMERATION_STACKS = {
  USE_LOCAL_TRACKS: "USE_LOCAL_TRACKS HOOK",
  USE_DEVICES: "USE_DEVICES HOOK",
  VIDEO_TOGGLE: "USE VIDEO TOGGLE HOOK"
};
