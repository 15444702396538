export enum ZoomEvents {
  ConnectionChange = "connection-change",
  DialoutStateChange = "dialout-state-change",
  ActiveSpeaker = "active-speaker",
  UserAdded = "user-added",
  UserRemoved = "user-removed",
  UserUpdated = "user-updated",
  ActiveShareChange = "active-share-change",
  PassivelyStopShare = "passively-stop-share"
}
