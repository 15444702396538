import {
  PhoneNumber,
  PhoneNumberFormat,
  PhoneNumberUtil
} from "google-libphonenumber";
import i18next from "i18next";
import moment from "moment";
import { object, string } from "yup";

import { EVENT_SIDEBAR_DATE_FORMAT_WITH_DAY } from "../constants/formats";
import {
  MEETING_ID_REGEX,
  MEETING_PIN_FORMAT_REGEX,
  UserConstraints
} from "../constants/validation";
import { CIQParticipant } from "../models/meeting";
import NotificationService from "./NotificationService";

export const UtilService = {
  nameToAvatarText: (fullName: string): string =>
    fullName
      ? fullName
          .split(/\s/)
          .map((part) => part.substring(0, 1).toUpperCase())
          .filter((v) => !!v)
          .slice(0, 2)
          .join("")
      : "",

  displayUserName(identity: string, participant?: CIQParticipant): string {
    const name = this.isDialInParticipant(identity)
      ? i18next.t("room:dialInNumber")
      : participant?.name;
    return name!;
  },

  isDialInParticipant(identity: string): boolean {
    return !this.validateEmail(identity);
  },

  convertUTCToActiveZone: (date: Date): Date => {
    const utcDate = moment.utc(date);
    const activeZoneDate = utcDate.clone().local();
    return activeZoneDate.toDate();
  },

  formatPhoneNumber: (number: string): string => {
    const phoneNumberFormatInstance = PhoneNumberUtil.getInstance();
    const phoneNumber: PhoneNumber =
      phoneNumberFormatInstance.parseAndKeepRawInput(number);

    const formatted = phoneNumberFormatInstance.format(
      phoneNumber,
      PhoneNumberFormat.INTERNATIONAL
    );

    const clearSpecial = formatted.replace(new RegExp(/[^\d]/, "g"), "");
    const replaced = clearSpecial.replace(/(\d{4})\d{3}/, "$1***");
    let i = 0;

    return formatted.replace(new RegExp(/\d/, "g"), () =>
      // eslint-disable-next-line no-plusplus
      replaced.charAt(i++)
    );
  },

  formatDate: (
    date: Date,
    format: string = EVENT_SIDEBAR_DATE_FORMAT_WITH_DAY
  ): string => moment(date).format(format),

  createParticipantDataMap(
    participants: CIQParticipant[]
  ): Map<string, CIQParticipant> {
    const participantDataMap = new Map<string, CIQParticipant>();
    participants.forEach((p) => {
      participantDataMap.set(p.email, p);
      if (p.phoneNumber) {
        participantDataMap.set(p.phoneNumber, p);
      }
    });
    return participantDataMap;
  },

  hasLocalVideoInputDevices(): Promise<boolean> {
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => devices.some((device) => device.kind === "videoinput"))
      .catch((err) => err);
  },

  validateEmail: (email: string): boolean => {
    const schema = object().shape({
      email: string().email().max(UserConstraints.MAX_EMAIL_LENGTH).required()
    });

    return schema.isValidSync({ email });
  },

  validateName: (name: string): boolean => {
    const schema = object().shape({
      name: string().max(UserConstraints.MAX_NAME_LENGTH).required()
    });

    return schema.isValidSync({ name });
  },

  copyToClipboard: (url: string): void => {
    if (navigator.clipboard === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isCopied = (window as { [key: string]: any })[
        // eslint-disable-next-line dot-notation
        "clipboardData"
      ].setData("Text", url);

      if (isCopied) {
        NotificationService.showSuccess(i18next.t("room:copiedToClipboard"));
      } else {
        NotificationService.showError(i18next.t("room:copiedToClipboardError"));
      }
    } else {
      // for other navigators
      navigator.clipboard
        .writeText(url)
        .then(() =>
          NotificationService.showSuccess(i18next.t("room:copiedToClipboard"))
        );
    }
  },

  validateContactNumber: (contactNumber: string): boolean => {
    const phoneNumberValidatingInstance = PhoneNumberUtil.getInstance();

    try {
      const phoneNumber: PhoneNumber =
        phoneNumberValidatingInstance.parseAndKeepRawInput(contactNumber);
      if (phoneNumberValidatingInstance.isValidNumber(phoneNumber)) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  },

  isValidMeetingId: (meetingId: string): boolean =>
    MEETING_ID_REGEX.test(meetingId),

  formatMeetingCodeId: (meetingPin: number): string =>
    meetingPin
      .toString()
      .replace(new RegExp(MEETING_PIN_FORMAT_REGEX, "g"), " ")
      .concat("#"),

  debouncer: (callback: Function) => {
    let timerId: number | undefined;

    return () => {
      if (timerId !== undefined) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => callback(), 5000);
    };
  }
};
