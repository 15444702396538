import { useCallback, useState } from "react";
import { useModalContext } from "..";
import {
  DEVICE_ENUMERATION_STACKS,
  VIDEO_PUBLISH_STACKS
} from "../../constants/errors";
import { TRACK_PUBLISH_STATE } from "../../constants/events";
import { AnalyticsService, UtilService } from "../../services";
import { PermissionModalEvents } from "../modalContext/usePermissionModal/UsePermissionModal";
import useIsTrackEnabled from "../useIsTrackEnabled/useTrackEnabled";
import useMeetingContext from "../useMeetingContext/UseMeetingContext";

interface UseLocalVideoToggle {
  isEnabled: boolean;
  toggleVideoVisibility: () => void;
  isCamPublishing: boolean;
}

export default function useLocalVideoToggle(): UseLocalVideoToggle {
  const [isCamPublishing, setIsCamPublishing] = useState(false);

  const {
    localVideoTrack,
    disableVideoTrack,
    getLocalVideoTrack,
    room: { localParticipant }
  } = useMeetingContext();

  const { togglePermissionModal } = useModalContext();

  const isEnabled = useIsTrackEnabled(localVideoTrack);

  const toggleVideoVisibility = useCallback(async () => {
    if (localVideoTrack) {
      const localTrackPublication =
        localParticipant?.unpublishTrack(localVideoTrack);
      localParticipant?.emit(
        TRACK_PUBLISH_STATE.TRACK_UNPUBLISHED,
        localTrackPublication
      );
      disableVideoTrack();
    } else {
      try {
        const hasVideoInputDevices =
          await UtilService.hasLocalVideoInputDevices();
        if (hasVideoInputDevices) {
          getLocalVideoTrack().then((track) => {
            if (localParticipant) {
              setIsCamPublishing(true);
              localParticipant
                .publishTrack(track)
                .catch((error) => {
                  AnalyticsService.videoPublishError(
                    error,
                    track,
                    VIDEO_PUBLISH_STACKS.VIDEO_TOGGLE
                  );
                })
                .finally(() => setIsCamPublishing(false));
            }
          });
        } else {
          togglePermissionModal(true, PermissionModalEvents.CAM_NOT_FOUND);
        }
      } catch (error) {
        AnalyticsService.deviceEnumerationError(
          error,
          DEVICE_ENUMERATION_STACKS.VIDEO_TOGGLE
        );
      }
    }
  }, [
    localVideoTrack,
    localParticipant,
    disableVideoTrack,
    getLocalVideoTrack,
    togglePermissionModal
  ]);

  return { isEnabled, toggleVideoVisibility, isCamPublishing };
}
