import clsx from "clsx";
import React from "react";
import { AudioTrack, LocalVideoTrack, RemoteVideoTrack } from "twilio-video";
import useIsTrackSwitchedOff from "../../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import { CIQParticipant } from "../../../models/meeting";
import VideoPreview from "../../videoPreview/VideoPreview";

import { useIsTrackEnabled, useParticipants } from "../../../hooks";
import ParticipantPlaceholder from "../participantPlaceholder/ParticipantPlaceholder";

interface ParticipantVideoPreviewProps {
  isDialInParticipant: boolean;
  name: string;
  track: LocalVideoTrack | RemoteVideoTrack;
  audioTrack?: AudioTrack;
  isLocalParticipant: boolean;
  ciqParticipantData?: CIQParticipant;
  isSelected?: boolean;
}

const ParticipantVideoPreview = ({
  name,
  track,
  audioTrack,
  isLocalParticipant,
  ciqParticipantData,
  isSelected,
  isDialInParticipant
}: ParticipantVideoPreviewProps): JSX.Element => {
  const participantsCount = useParticipants().length;
  const isTrackSwitchOff = useIsTrackSwitchedOff(track);
  const isAudioEnabled = useIsTrackEnabled(audioTrack as AudioTrack);

  const canBePinned = participantsCount > 0;

  if (isTrackSwitchOff) {
    return (
      <ParticipantPlaceholder
        isDialInParticipant={isDialInParticipant}
        name={name}
        ciqParticipantData={ciqParticipantData}
      />
    );
  }

  return (
    <>
      <div className="top-container">
        <VideoPreview track={track} isLocalParticipant={isLocalParticipant} />
      </div>
      <div className="meta-container">
        <div className="bottom-container">
          <div
            className={clsx("mic-btn", {
              disabled: !isAudioEnabled
            })}
          >
            <i
              className={clsx("ciq-icon", {
                "ciq-call-audio-on": isAudioEnabled,
                "ciq-call-audio-off": !isAudioEnabled
              })}
            />
          </div>
          <div className="name-container">
            <p>{name}</p>
          </div>
          {canBePinned && (
            <div
              className={clsx("pin-icon", {
                pinned: isSelected
              })}
            >
              <i
                className={clsx("ciq-icon", {
                  "ciq-push-pin-off": isSelected,
                  "ciq-push-pin": !isSelected
                })}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ParticipantVideoPreview;

ParticipantVideoPreview.defaultProps = {
  ciqParticipantData: undefined,
  audioTrack: undefined,
  isSelected: false
};
