import { useCallback, useState } from "react";

import { useMeetingContext } from "..";
import { AUDIO_PUBLISH_STACKS } from "../../constants/errors";
import { AnalyticsService } from "../../services";
import useIsTrackEnabled from "../useIsTrackEnabled/useTrackEnabled";

interface UseLocalAudioToggle {
  isEnabled: boolean;
  toggleLocalAudio: () => void;
  isMicPublishing: boolean;
}

export default function useLocalAudioToggle(): UseLocalAudioToggle {
  const [isMicPublishing, setIsMicPublishing] = useState(false);
  const {
    localAudioTrack,
    enableAudioTrack,
    disableAudioTrack,
    getLocalAudioTrack,
    room: { localParticipant }
  } = useMeetingContext();
  const isEnabled = useIsTrackEnabled(localAudioTrack);
  const toggleLocalAudio = useCallback(() => {
    if (localAudioTrack) {
      if (isEnabled) {
        disableAudioTrack();
      } else {
        enableAudioTrack();
      }
    } else {
      // Re-fetch audio device if not exist
      getLocalAudioTrack().then((track) => {
        // Publish track to call if connected
        if (localParticipant) {
          setIsMicPublishing(true);
          localParticipant
            .publishTrack(track)
            .catch((error) => {
              AnalyticsService.audioPublishError(
                error,
                track,
                AUDIO_PUBLISH_STACKS.AUDIO_TOGGLE
              );
            })
            .finally(() => setIsMicPublishing(false));
        }
      });
    }
  }, [
    isEnabled,
    enableAudioTrack,
    disableAudioTrack,
    getLocalAudioTrack,
    localAudioTrack,
    localParticipant
  ]);

  return { isEnabled, toggleLocalAudio, isMicPublishing };
}
