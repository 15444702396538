import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Button } from "reactstrap";
import { CIQTooltip } from "..";
import { APP_DEVICE_MEDIA_QUERIES } from "../../constants/ui";
import { useMeetingContext, useParticipants } from "../../hooks";

export function ParticipantSidebarButton(): JSX.Element {
  const { toggleParticipantSidebar, isEventSidebarOpen, toggleEventSidebar } =
    useMeetingContext();
  const { t } = useTranslation("twilioRoom");

  const numOfParticipants = useParticipants().length + 1;

  const handleUsersClicked = (): void => {
    if (isEventSidebarOpen) toggleEventSidebar();

    toggleParticipantSidebar();
  };

  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });

  return (
    <Button
      className="circle-btn grey no-border btn-participants"
      size="lg"
      onClick={handleUsersClicked}
      data-tip
      data-for="participant-btn-tool-tip"
    >
      <i className="ciq-icon ciq-people">
        {isLargeDevice && (
          <span className="participant-count">{numOfParticipants}</span>
        )}
      </i>
      <CIQTooltip id="participant-btn-tool-tip" content={t("participants")} />
    </Button>
  );
}
