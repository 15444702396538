import { ErrorInfo } from "react";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  NetworkQualityStats
} from "twilio-video";
import { ConnectCrashAnalytics } from "../models/meeting";

const INSPECTLET_TAGS = {
  EMAIL: "EMAIL",
  NAME: "NAME",
  MEETING_ID: "MEETING_ID",

  // EVENTS
  INITIAL_CAMERA_LOAD: "INITIAL_CAMERA_LOAD",
  INITIAL_MICROPHONE_LOAD: "INITIAL_MICROPHONE_LOAD",
  TWILLIO_CONNECTED: "TWILLIO_CONNECTED",
  TWILLIO_DISCONNECT: "TWILLIO_DISCONNECT",
  PUSHER_CONNECTED: "PUSHER_CONNECTED",
  PARTICIPANT_POOR_NETWORK_QUALITY: "PARTICIPANT_POOR_NETWORK_QUALITY",

  // ERRORS
  TWILLIO_CONNECT_ERROR: "TWILLIO_CONNECT_ERROR",
  TWILLIO_DISCONNECT_ERROR: "TWILLIO_DISCONNECT_ERROR",
  VIDEO_PUBLISH_ERROR: "VIDEO_PUBLISH_ERROR",
  AUDIO_PUBLISH_ERROR: "AUDIO_PUBLISH_ERROR",
  SCREEN_PUBLISH_ERROR: "SCREEN_PUBLISH_ERROR",
  PUSHER_CONNECTION_ERROR: "PUSHER_CONNECTION_ERROR",
  DEVICE_PERMISSION_ERROR: "DEVICE_PERMISSION_ERROR",
  DEVICE_ENUMERATION_ERROR: "DEVICE_ENUMERATION_ERROR",
  MEETING_UNHANDLED_EXCEPTION: "MEETING_UNHANDLED_EXCEPTION",
  APP_UNHANDLED_EXCEPTION: "APP_UNHANDLED_EXCEPTION"
};

/* eslint-disable no-underscore-dangle */
class AnalyticsService {
  constructor() {
    /* eslint-disable */
    window.__insp = window.__insp || [];
  }

  errorObjectBuilder(object: any) {
    const timestampAdded = { ...object, timeStamp: new Date().toISOString() };
    return JSON.stringify(timestampAdded);
  }

  initialCameraLoad({
    dimensions,
    kind,
    isEnabled,
    mediaStreamTrack: { label }
  }: LocalVideoTrack): void {
    const deviceData = this.errorObjectBuilder({
      device: {
        label,
        dimensions,
        kind,
        isEnabled
      }
    });
    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.INITIAL_CAMERA_LOAD}`]: deviceData }
    ]);
  }

  initialMicrophoneLoad({
    kind,
    isEnabled,
    mediaStreamTrack: { label }
  }: LocalAudioTrack): void {
    const deviceData = this.errorObjectBuilder({
      device: {
        label,
        kind,
        isEnabled
      }
    });
    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.INITIAL_MICROPHONE_LOAD}`]: deviceData }
    ]);
  }

  twillioConnectError(error: any): void {
    const deviceData = this.errorObjectBuilder({ error: `${error}` });
    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.TWILLIO_CONNECT_ERROR}`]: deviceData }
    ]);
  }

  connectedToMeeting(
    email: string,
    name: string,
    meetingId: string,
    localAudioTrack?: LocalAudioTrack,
    localVideoTrack?: LocalVideoTrack
  ): void {
    let audioInstance = {};
    let videoInstance = {};

    if (localAudioTrack) {
      const {
        isEnabled,
        mediaStreamTrack: { label }
      } = localAudioTrack;
      audioInstance = {
        label,
        isEnabled
      };
    }

    if (localVideoTrack) {
      const {
        isEnabled,
        dimensions,
        mediaStreamTrack: { label }
      } = localVideoTrack;
      videoInstance = {
        label,
        isEnabled,
        dimensions
      };
    }

    const connectedData = this.errorObjectBuilder({
      mic: audioInstance,
      camera: videoInstance
    });
    window.__insp.push(["tagSession", { [`${INSPECTLET_TAGS.EMAIL}`]: email }]);
    window.__insp.push(["tagSession", { [`${INSPECTLET_TAGS.NAME}`]: name }]);
    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.MEETING_ID}`]: meetingId }
    ]);
    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.TWILLIO_CONNECTED}`]: connectedData }
    ]);
  }

  identifyUser(email: string) {
    window.__insp.push(["identify", email]);
    window.__insp.push(["tagSession", { [`${INSPECTLET_TAGS.EMAIL}`]: email }]);
  }

  twillioDisconnectSuccess() {
    const disconnectData = this.errorObjectBuilder({});
    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.TWILLIO_DISCONNECT}`]: disconnectData }
    ]);
  }

  twillioDisconnectError(errorCode: string, errorMessage: string): void {
    const twillioError = this.errorObjectBuilder({ errorCode, errorMessage });

    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.TWILLIO_DISCONNECT_ERROR}`]: twillioError }
    ]);
  }

  videoPublishError(
    error: any,
    { id, name, kind, mediaStreamTrack, dimensions }: LocalVideoTrack,
    stack: string
  ): void {
    const videoPublishError = this.errorObjectBuilder({
      error: `${error}`,
      stack,
      device: {
        id,
        name,
        kind,
        dimensions,
        label: mediaStreamTrack?.label || "Not permitted"
      }
    });
    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.VIDEO_PUBLISH_ERROR}`]: videoPublishError }
    ]);
  }

  audioPublishError(
    error: any,
    { id, name, kind, mediaStreamTrack }: LocalAudioTrack,
    stack: string
  ): void {
    const audioPublishError = this.errorObjectBuilder({
      error: `${error}`,
      stack,
      device: {
        id,
        name,
        kind,
        label: mediaStreamTrack?.label || "Not permitted"
      }
    });
    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.AUDIO_PUBLISH_ERROR}`]: audioPublishError }
    ]);
  }

  screenPublishError(error: any): void {
    const screenSharePublishError = this.errorObjectBuilder({
      error: `${error}`
    });
    window.__insp.push([
      "tagSession",
      {
        [`${INSPECTLET_TAGS.SCREEN_PUBLISH_ERROR}`]: screenSharePublishError
      }
    ]);
  }

  pusherConnectionSuccess(): void {
    const pusherConnectSuccess = this.errorObjectBuilder({});
    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.PUSHER_CONNECTED}`]: pusherConnectSuccess }
    ]);
  }

  pusherConnectError(error: any): void {
    const pusherConnectError = this.errorObjectBuilder({ error: `${error}` });

    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.PUSHER_CONNECTION_ERROR}`]: pusherConnectError }
    ]);
  }

  devicePermissionErrors(trackKind: string, error: any): void {
    const permissionErrors = this.errorObjectBuilder({
      error: `${error}`
    });

    window.__insp.push([
      "tagSession",
      {
        [`${
          INSPECTLET_TAGS.DEVICE_PERMISSION_ERROR
        }_${trackKind.toLocaleUpperCase()}`]: permissionErrors
      }
    ]);
  }

  deviceEnumerationError(error: any, stack: string) {
    const deviceEnumerationError = this.errorObjectBuilder({
      error: `${error}`,
      stack
    });
    window.__insp.push([
      "tagSession",
      {
        [`${INSPECTLET_TAGS.DEVICE_ENUMERATION_ERROR}`]: deviceEnumerationError
      }
    ]);
  }

  meetingUnhandledException(errorData: ConnectCrashAnalytics) {
    const errorObj = this.errorObjectBuilder({
      errorData: errorData
    });

    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.MEETING_UNHANDLED_EXCEPTION}`]: errorObj }
    ]);
  }

  appUnhandledException(error: Error, errorInfo: ErrorInfo) {
    const errorObj = this.errorObjectBuilder({
      error: error.message,
      errorInfo: errorInfo.componentStack
    });

    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.APP_UNHANDLED_EXCEPTION}`]: errorObj }
    ]);
  }

  poorNetworkQuality(networkQualityStats: NetworkQualityStats) {
    const networkStats = this.errorObjectBuilder(networkQualityStats);
    window.__insp.push([
      "tagSession",
      {
        [`${INSPECTLET_TAGS.PARTICIPANT_POOR_NETWORK_QUALITY}`]: networkStats
      }
    ]);
  }
}

export default new AnalyticsService();
