import moment from "moment";
import { UtilService } from ".";
import {
  EVENT_SIDEBAR_PANEL_FORMAT,
  EVENT_SIDEBAR_PANEL_START_TIME_FORMAT,
  EVENT_SIDEBAR_PANEL_START_TIME_FORMAT_WITH_MINUTES,
  EVENT_SIDEBAR_PANEL_VIEW_DATE_FORMAT
} from "../constants/formats";
import { EventConstraints } from "../constants/validation";

export const EventService = {
  generateEventDate: (start: Date, end: Date): string => {
    const utcStartDate = UtilService.convertUTCToActiveZone(start);
    const utcEndDate = UtilService.convertUTCToActiveZone(end);

    if (moment(utcStartDate).isSame(moment(utcEndDate), "date")) {
      const date = UtilService.formatDate(
        utcStartDate,
        EVENT_SIDEBAR_PANEL_VIEW_DATE_FORMAT
      );
      return date;
    }

    const startData = UtilService.formatDate(
      utcStartDate,
      EVENT_SIDEBAR_PANEL_VIEW_DATE_FORMAT
    );
    const endDate = UtilService.formatDate(
      utcEndDate,
      EVENT_SIDEBAR_PANEL_VIEW_DATE_FORMAT
    );
    return `${startData} - ${endDate}`;
  },

  generateEventTime: (eventStart: Date, eventEnd: Date): string => {
    const utcStartDate = UtilService.convertUTCToActiveZone(eventStart);
    const utcEndDate = UtilService.convertUTCToActiveZone(eventEnd);

    let start;
    let end;
    if (moment(utcStartDate).isSame(moment(utcEndDate), "date")) {
      if (
        (+moment(utcStartDate).format("HH") > 12 &&
          +moment(utcEndDate).format("HH") > 12) ||
        (+moment(utcStartDate).format("HH") < 12 &&
          +moment(utcEndDate).format("HH") < 12)
      ) {
        if (utcStartDate.getMinutes() === 0) {
          start = UtilService.formatDate(
            utcStartDate,
            EVENT_SIDEBAR_PANEL_START_TIME_FORMAT
          );
        } else {
          start = UtilService.formatDate(
            utcStartDate,
            EVENT_SIDEBAR_PANEL_START_TIME_FORMAT_WITH_MINUTES
          );
        }
        end = UtilService.formatDate(utcEndDate, EVENT_SIDEBAR_PANEL_FORMAT);
        return `${start} - ${end}`;
      }
      start = UtilService.formatDate(utcStartDate, EVENT_SIDEBAR_PANEL_FORMAT);
      end = UtilService.formatDate(utcEndDate, EVENT_SIDEBAR_PANEL_FORMAT);
      return `${start} - ${end}`;
    }
    start = UtilService.formatDate(utcStartDate, EVENT_SIDEBAR_PANEL_FORMAT);
    end = UtilService.formatDate(utcEndDate, EVENT_SIDEBAR_PANEL_FORMAT);
    return `${start} - ${end}`;
  },

  isExpiredEvent: (end: Date): boolean => {
    const utcEndDate = UtilService.convertUTCToActiveZone(end);

    return (
      moment().diff(moment(utcEndDate), "days", true) >
      EventConstraints.EXPIRE_FILE_DURATION
    );
  },

  getFileExtension: (fileName: string): string =>
    fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase(),

  humanizeFileName: (fileName: string): string =>
    fileName.slice(0, fileName.indexOf(".")),

  humanizeFileSize: (fileSize: number): string => {
    const i =
      fileSize === 0 ? 0 : Math.floor(Math.log(fileSize) / Math.log(1024));
    return `${(fileSize / 1024 ** i).toFixed(2)} ${
      ["B", "KB", "MB", "GB", "TB"][i]
    }`;
  }
};
