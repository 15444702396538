import React from "react";
import { useTranslation } from "react-i18next";
import { useMeetingContext } from "../../../hooks";
import VideoPreview from "../../videoPreview/VideoPreview";

const LocalVideoPreview: React.FC = (): JSX.Element => {
  const { localVideoTrack, isCamRequesting } = useMeetingContext();

  const { t } = useTranslation("lobby");

  return (
    <>
      <div className="video-preview-container">
        {isCamRequesting && <h3>{t("deviceLoading")}</h3>}
        {!isCamRequesting && !localVideoTrack && (
          <div className="cam-off-container">
            <h3>{t("cameraOff")}</h3>
          </div>
        )}
        {localVideoTrack && (
          <VideoPreview track={localVideoTrack} isLocalParticipant />
        )}
      </div>
    </>
  );
};

export default LocalVideoPreview;
