import React from "react";
import { RemoteAudioTrack, RemoteTrackPublication } from "twilio-video";
import { useTrack } from "../../../hooks";
import ParticipantAudioTrack from "../../audioTrack/ParticipantAudioTrack";

interface PublicationProps {
  publication: RemoteTrackPublication;
}

export default function AudioPublication({
  publication
}: PublicationProps): JSX.Element | null {
  const track = useTrack(publication);

  if (!track) return null;

  return <ParticipantAudioTrack track={track as RemoteAudioTrack} />;
}
