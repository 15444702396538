import clsx from "clsx";
import React from "react";
import Select, { components, SingleValueProps } from "react-select";

import { SelectOption } from "../../models/modal";

interface CustomSelectProps {
  options: SelectOption[];
  defaultValue?: SelectOption;
  noOptionsMessage: string;
  onSelectChange: (value: string) => void;
  customStyle?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  onBlur?: () => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  onSelectChange,
  defaultValue,
  customStyle,
  noOptionsMessage,
  isSearchable = false,
  placeholder,
  onBlur
}: CustomSelectProps) => {
  const SingleValue = (
    singleValueProps: SingleValueProps<SelectOption>
  ): JSX.Element => {
    const { customLabel, label } = singleValueProps.data;
    return (
      <components.SingleValue {...singleValueProps}>
        {customLabel || label}
      </components.SingleValue>
    );
  };

  return (
    <Select
      value={defaultValue}
      options={options}
      noOptionsMessage={(): string => noOptionsMessage}
      className={clsx("ciqs-select", { "with-icon": customStyle })}
      classNamePrefix="ciqs-select"
      isSearchable={isSearchable}
      components={{
        DropdownIndicator: (): null => null,
        IndicatorSeparator: (): null => null,
        SingleValue
      }}
      onChange={(value): void => {
        onSelectChange((value as SelectOption).value);
      }}
      placeholder={placeholder}
      onBlur={onBlur}
      maxMenuHeight={150}
      menuPosition="fixed"
    />
  );
};

export default CustomSelect;

CustomSelect.defaultProps = {
  defaultValue: undefined,
  customStyle: false,
  isSearchable: undefined,
  onBlur: undefined,
  placeholder: undefined
};
