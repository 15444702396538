import { useContext } from "react";
import {
  MeetingContext,
  MeetingContextState
} from "../../contexts/MeetingContext";

export default function useMeetingContext(): MeetingContextState {
  const context = useContext(MeetingContext);
  if (!context) {
    throw new Error(
      "useMeetingContext must be used within a MeetingContextProvider"
    );
  }
  return context;
}
