export const TRACK_KIND = {
  AUDIO: "audio",
  VIDEO: "video"
};

export const DEVICE_TYPES = {
  AUDIO_INPUT: "audioinput",
  AUDIO_OUTPUT: "audiooutput",
  VIDEO_INPUT: "videoinput"
};
