import React from "react";
import { Alert } from "reactstrap";
import { ErrorResponse } from "../../models/api";

interface AlertViewProps {
  feedback: ErrorResponse;
}

/**
 * @deprecated use ant design instead
 */
const AlertView: React.FC<AlertViewProps> = ({ feedback }): JSX.Element => {
  const { message } = feedback;

  return <Alert color="danger">{message}</Alert>;
};

export default AlertView;
