import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Participant } from "twilio-video";

import { useDominantSpeaker, useMeetingContext } from "..";
import { PARTICIPANT_STATE } from "../../constants/events";
import { MAX_PARTICIPANT_VIEWS } from "../../constants/meeting";
import { APP_DEVICE_MEDIA_QUERIES } from "../../constants/ui";

export default function useParticipants(): Participant[] {
  const { room } = useMeetingContext();
  const dominantSpeaker = useDominantSpeaker();
  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });

  const { PARTICIPANT_CONNECTED, PARTICIPANT_DISCONNECTED } = PARTICIPANT_STATE;

  const [participants, setParticipants] = useState<Participant[]>(
    Array.from(room.participants.values())
  );

  // Added max listeners count
  room.setMaxListeners(35 + participants.length * 4);

  const moveParticipant = (
    participantsList: Participant[],
    oldIndex: number
  ): Participant[] => {
    const participantsArr = participantsList.slice();
    const dominantParticipant = participantsArr.splice(oldIndex, 1)[0];
    participantsArr.splice(0, 0, dominantParticipant);
    return participantsArr;
  };

  useEffect(() => {
    if (dominantSpeaker) {
      const dominantSpeakerIndex = participants.findIndex(
        (participant) => participant === dominantSpeaker
      );

      // Main participant always included as first view
      const availableSlotCount = MAX_PARTICIPANT_VIEWS(!isLargeDevice) - 1;

      const isAllWithInView = participants.length < availableSlotCount;
      const isNextDomSpeakerWithinView =
        dominantSpeakerIndex < availableSlotCount;

      if (!isAllWithInView && !isNextDomSpeakerWithinView) {
        setParticipants(moveParticipant(participants, dominantSpeakerIndex));
      }
    }
  }, [dominantSpeaker, participants, isLargeDevice]);

  useEffect(() => {
    const participantConnected = (participant: Participant): void => {
      setParticipants([...participants, participant]);
    };

    const participantDisconnected = (participant: Participant): void => {
      setParticipants(participants.filter((p) => p !== participant));
    };

    room.on(PARTICIPANT_CONNECTED, participantConnected);
    room.on(PARTICIPANT_DISCONNECTED, participantDisconnected);

    return (): void => {
      room.off(PARTICIPANT_CONNECTED, participantConnected);
      room.off(PARTICIPANT_DISCONNECTED, participantDisconnected);
    };
  });

  return participants;
}
