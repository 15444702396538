import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import { CIQFileIcon } from ".";
import { useMeetingContext } from "../../hooks";
import { NotificationService } from "../../services";
import { EventService } from "../../services/EventService";

const EventSidebar: React.FC = (): JSX.Element => {
  const { t } = useTranslation("eventSidebar");

  const {
    toggleEventSidebar,
    isEventSidebarOpen,
    meeting,
    getTimezoneDisplayText
  } = useMeetingContext();

  const { title, endTime, startTime, location, attachments, notes } = meeting!;

  const timezoneDisplayText = getTimezoneDisplayText();
  const isExpiredEvent = EventService.isExpiredEvent(endTime);

  const handleAttachmentClicked = (downloadUrl: string): void => {
    if (isExpiredEvent) {
      NotificationService.showError(t("linkHasExpired"));
    } else {
      const iframe = document.getElementById("docIframe");
      iframe?.setAttribute("src", downloadUrl);
    }
  };

  return (
    <SimpleBar
      className={clsx("event-sidebar simplebar-light", {
        "is-open": isEventSidebarOpen
      })}
    >
      <iframe
        id="docIframe"
        title="docIframe"
        width={0}
        height={0}
        style={{ display: "none" }}
      />
      <div className="event-sidebar-body">
        <div className="top-container">
          <div className="sidebar-header">
            <h2>{title}</h2>
            <div className="btn-close" onClick={toggleEventSidebar}>
              <i className="ciq-icon ciq-close" />
            </div>
          </div>
          <div className="sidebar-body">
            <div className="detail-row">
              <div className="detail-icon">
                <i className="ciq-icon ciq-calender" />
              </div>
              <div className="detail-text">
                {EventService.generateEventDate(
                  new Date(startTime),
                  new Date(endTime)
                )}
              </div>
            </div>
            <div className="detail-row-container">
              <div className="left-container">
                <div className="detail-icon">
                  <i className="ciq-icon ciq-activity" />
                </div>
                <div className="detail-text">
                  {EventService.generateEventTime(startTime, endTime)}
                </div>
              </div>
              <div className="right-container">
                {timezoneDisplayText && (
                  <div className="detail-timezone">
                    {timezoneDisplayText.split(" ")[0]}
                  </div>
                )}
              </div>
            </div>
            {location && (
              <div className="detail-row">
                <div className="detail-icon">
                  <i className="ciq-icon ciq-component-icon-ic-place" />
                </div>
                <div className="detail-text">{location}</div>
              </div>
            )}
          </div>
        </div>

        <hr />

        {(attachments.length > 0 || notes) && (
          <div className="bottom-container">
            <h2>{t("notes&Attachments")}</h2>
            {notes && (
              <div className="notes-container">
                <p>{notes}</p>
              </div>
            )}
            {attachments.length > 0 && (
              <div className="attachment-list-container">
                {attachments.map((attachment) => (
                  <div
                    className="file-item"
                    key={attachment.downloadUrl}
                    onClick={(): void =>
                      handleAttachmentClicked(attachment.downloadUrl)
                    }
                  >
                    <div className="left-container">
                      <div className="file-type-icon">
                        {CIQFileIcon(attachment.fileName)}
                      </div>
                      <div className="detail-container">
                        <div className="file-name">
                          {EventService.humanizeFileName(attachment.fileName)}
                        </div>
                        <div className="file-size">
                          {EventService.humanizeFileSize(attachment.fileSize)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </SimpleBar>
  );
};

export default EventSidebar;
