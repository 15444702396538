import { Observable } from "rxjs";

import { MEETING_API } from "../constants/api";
import { USER_STATE } from "../constants/meeting";
import { ApiSuccessResponse } from "../models/api";
import { ConnectCrashAnalytics } from "../models/meeting";
import { LegacyRestService } from "./LegacyRestService";

export const DiagnosticService = {
  sendUserJoinedState: (
    meetingId: string,
    email?: string,
    twilioToken?: string
  ): Observable<ApiSuccessResponse> =>
    LegacyRestService.post(MEETING_API.POST_USER_STATE(meetingId), {
      email,
      twilioToken,
      state: USER_STATE.JOINED
    }),

  sendUserLeftState: (
    meetingId: string,
    email?: string,
    twilioToken?: string
  ): Observable<ApiSuccessResponse> =>
    LegacyRestService.post(MEETING_API.POST_USER_STATE(meetingId), {
      email,
      twilioToken,
      state: USER_STATE.LEFT
    }),

  sendAppCrashData: (
    userEmail: string,
    errorData: ConnectCrashAnalytics
  ): Observable<ApiSuccessResponse> =>
    LegacyRestService.post<ApiSuccessResponse>(
      MEETING_API.POST_CLIENT_CRASH_DATA(),
      {
        userEmail,
        errorData
      }
    )
};
