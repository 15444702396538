import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { Subscription } from "rxjs";
import { CIQTooltip } from "../..";
import { useMeetingContext } from "../../../hooks";
import { DiagnosticService, LegacyMeetingService } from "../../../services";
import PusherService from "../../../services/PusherService";

export function DisconnectButton(): JSX.Element {
  const { room, userEmail, twilioToken } = useMeetingContext();
  const { t } = useTranslation("twilioRoom");

  const [isLoading, setIsLoading] = useState(false);

  const userStateSubscription = useRef(new Subscription());

  useEffect(
    () => (): void => {
      userStateSubscription.current.unsubscribe();
    },
    []
  );

  const handleRoomDisconnect = (): void => {
    setIsLoading(false);
    room.disconnect();
    room.removeAllListeners();

    PusherService.unsubscribeFromChannel();
  };

  const handleDisconnectClicked = (): void => {
    setIsLoading(true);
    const meetingId = LegacyMeetingService.getMeetingIdFromURL();
    if (meetingId) {
      userStateSubscription.current = DiagnosticService.sendUserLeftState(
        meetingId,
        userEmail,
        twilioToken
      ).subscribe(
        () => {
          handleRoomDisconnect();
        },
        () => {
          handleRoomDisconnect();
        }
      );
    }
  };

  return (
    <Button
      className="circle-btn call-end-btn"
      size="lg"
      onClick={handleDisconnectClicked}
      disabled={isLoading}
      data-tip
      data-for="local-disconnect-btn-tool-tip"
    >
      <i className="ciq-icon ciq-call-end" />
      <CIQTooltip
        id="local-disconnect-btn-tool-tip"
        content={t("leaveCall")}
        customClass="danger"
      />
    </Button>
  );
}
