import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import queryString from "query-string";
import { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button, FormGroup, Input, Label } from "reactstrap";

import { RECAPTCHA_ACTION } from "../../../constants/app";
import { GUEST_APPROVAL_STATE } from "../../../constants/meeting";
import { useMeetingContext } from "../../../hooks";
import { ErrorResponse } from "../../../models/api";
import { UtilService } from "../../../services";
import AlertView from "../../alert/AlertView";
import JoinLoadingState from "./JoinLoadingState";
import { joinTwilioMeetingSchema } from "./joinTwilioMeetingSchema";

type JoinTwilioMeetingFormValues = {
  email: string;
  name: string;
};

export default function JoinTwilioMeeting() {
  const { t } = useTranslation("lobby");
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    connect,
    meetingError,
    isConnecting,
    approvalType,
    setMeetingLoading,
    setMeetingError,
    isCamRequesting,
    isMicRequesting
  } = useMeetingContext();

  // URL Decoding
  const parsedSearch = queryString.parse(location.search) as any;
  const extractedEmail = parsedSearch.email;
  const extractedName = parsedSearch.name?.trim();
  const sanitizedEmail = UtilService.validateEmail(extractedEmail)
    ? extractedEmail
    : "";
  const sanitizedName = UtilService.validateName(extractedName)
    ? extractedName
    : "";

  const {
    register,
    errors,
    handleSubmit,
    formState,
    reset,
    setValue,
    getValues
  } = useForm<JoinTwilioMeetingFormValues>({
    defaultValues: {
      email: sanitizedEmail,
      name: sanitizedName
    },
    mode: "all",
    reValidateMode: "onSubmit",
    resolver: yupResolver(joinTwilioMeetingSchema)
  });

  const { DECLINED, PENDING } = GUEST_APPROVAL_STATE;
  const isDeclined = approvalType === DECLINED;

  useEffect(() => {
    if (!isConnecting && approvalType === undefined) {
      reset();
    }
  }, [isConnecting, reset, approvalType]);

  useEffect(() => {
    const connectToCall = async (): Promise<void> => {
      try {
        const token = await executeRecaptcha?.(RECAPTCHA_ACTION);
        const userName = getValues("name").trim();
        const email = getValues("email").trim();

        connect(userName, email, token!);
      } catch (error) {
        setMeetingError({ message: t("errorOccurred") } as ErrorResponse);
      }
    };

    if (
      !isCamRequesting &&
      !isMicRequesting &&
      isConnecting &&
      formState.isSubmitted
    ) {
      connectToCall();
    }
  }, [
    isCamRequesting,
    isMicRequesting,
    formState.isSubmitted,
    connect,
    executeRecaptcha,
    getValues,
    setValue,
    setMeetingError,
    t,
    isConnecting
  ]);

  const handleOnFormSubmit = handleSubmit(() => {
    setMeetingLoading();
  });

  const renderControlAction = (): JSX.Element => {
    if (isConnecting || approvalType === PENDING) {
      return <JoinLoadingState />;
    }

    return (
      <Button
        type="submit"
        color="primary"
        size="lg"
        disabled={!formState.isValid}
        className="shadow-none"
      >
        <p>{t("joinNow")}</p>
      </Button>
    );
  };

  const renderWelcome = (): JSX.Element => (
    <>
      <h4>{t("welcome")}</h4>
      <div className="welcomeMessageContainer">
        <span className="welcomeMessageBold">{t("welcomeMessage")}</span>
        <span className="welcomeMessageNormal">{t("welcomeMessage2")}</span>
      </div>
      {meetingError && <AlertView feedback={meetingError} />}
      <form onSubmit={handleOnFormSubmit}>
        <FormGroup className={clsx({ "is-invalid": errors.name })}>
          <Input
            innerRef={register}
            name="name"
            placeholder={t("namePlaceholder")}
            type="text"
            onChange={(e): void => {
              setValue("name", e.target.value);
            }}
            disabled={isConnecting}
          />
          {errors.name && (
            <Label className="invalid-feedback">{errors.name.message}</Label>
          )}
        </FormGroup>
        <FormGroup className={clsx({ "is-invalid": errors.email })}>
          <Input
            innerRef={register}
            name="email"
            placeholder={t("emailPlaceholder")}
            type="text"
            onChange={(e): void => {
              setValue("email", e.target.value);
            }}
            disabled={isConnecting}
          />
          {errors.email && (
            <Label className="invalid-feedback">{errors.email.message}</Label>
          )}
        </FormGroup>
        {renderControlAction()}
      </form>
    </>
  );

  const renderAccessDenied = (): JSX.Element => (
    <>
      <h4>{t("accessDenied")}</h4>
      <p>{t("accessDeniedMessage")}</p>
    </>
  );

  return (
    <div className="join-container">
      {isDeclined ? renderAccessDenied() : renderWelcome()}
    </div>
  );
}
