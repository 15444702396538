import React from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { EX_LINK_DEVICE_ENABLE_HELP } from "../../constants/externalLinks";
import { useModalContext } from "../../hooks";

const PermissionModal = (): JSX.Element => {
  const { t } = useTranslation("permissionModal");
  const {
    isPermissionModalToggle,
    togglePermissionModal,
    permissionModalContent
  } = useModalContext();

  const { title, icon, message } = permissionModalContent;

  const modalPortal = document.getElementById("modal-portal");

  return createPortal(
    <Modal
      isOpen={isPermissionModalToggle}
      toggle={(): void => togglePermissionModal(!isPermissionModalToggle)}
      className="permission-modal"
    >
      <ModalBody>
        <div className="device-icon">
          <span className={`ciq-icon ${icon}`} />
        </div>

        <div className="blocked-message">
          <p className="title">{title}</p>
          <p>{message || t("enableMessage")}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        {!message && (
          <Button
            size="sm"
            color="primary"
            className="footer-btn btn-bold"
            onClick={(): void => {
              togglePermissionModal(false);
              window.open(EX_LINK_DEVICE_ENABLE_HELP, "_blank");
            }}
          >
            {t("getHelp")}
          </Button>
        )}
        <Button
          size="sm"
          color={message ? "primary" : "secondary"}
          className="footer-btn btn-bold"
          onClick={(): void => togglePermissionModal(false)}
        >
          {t("dismiss")}
        </Button>
      </ModalFooter>
    </Modal>,
    modalPortal!
  );
};

export default PermissionModal;
