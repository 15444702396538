import clsx from "clsx";
import React, { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Button, Col, Row } from "reactstrap";
import { Subscription } from "rxjs";

import SimpleBar from "simplebar-react";
import { BottomActionBar, SettingsModalToggle } from "..";
import EventSidebar from "../eventPanel/EventSidebar";
import Header from "../header/Header";
import ParticipantsList from "./participantsList/ParticipantsList";

import { STACK_MODE } from "../../constants/meeting";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_ROOM,
  UI_WINDOW_HEIGHT
} from "../../constants/ui";
import {
  useMainParticipant,
  useMeetingContext,
  useParticipants,
  useStackMode
} from "../../hooks";
import { ErrorResponse } from "../../models/api";
import { LegacyMeetingService, NotificationService } from "../../services";
import JoinRequests from "../joinRequests/JoinRequests";
import { MainParticipant } from "../mainParticipant/MainParticipant";
import ParticipantSideBar from "../participantSideBar/participantsTab/ParticipantSideBar";
import PoorConnectionStrip from "./poorConnectionStrip/PoorConnectionStrip";
import RecordingIndicator from "./recordingIndicator/RecordingIndicator";

function TwilioRoom(): JSX.Element {
  const { t } = useTranslation("twilioRoom");
  const mainParticipant = useMainParticipant();
  const {
    isParticipantSidebarOpen,
    isEventSidebarOpen,
    setTimezones,
    meeting,
    toggleParticipantSidebar,
    toggleEventSidebar,
    selectedParticipant
  } = useMeetingContext();

  const { GRID, HORIZONTAL } = STACK_MODE;
  const { title } = meeting!;
  const fetchTimezonesSubscription = useRef(new Subscription());

  useEffect(() => {
    document.title = t("pageTitle", { title });
  }, [t, title]);

  useEffect(() => {
    fetchTimezonesSubscription.current =
      LegacyMeetingService.getTimezones().subscribe(
        ({ items }) => {
          setTimezones(items);
        },
        (error: ErrorResponse): void => {
          NotificationService.showError(error.message);
        }
      );
    return (): void => {
      fetchTimezonesSubscription.current.unsubscribe();
    };
  }, [setTimezones]);

  const handleInfoClicked = (): void => {
    if (isParticipantSidebarOpen) toggleParticipantSidebar();

    toggleEventSidebar();
  };

  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });

  const stackMode = useStackMode();
  const participantsCount = useParticipants().length;
  const isLocalParticipantOnly = participantsCount === 0;

  const isOwnScreenShare = isLocalParticipantOnly && stackMode !== GRID;

  let columnSize = 12;

  if (!isOwnScreenShare) {
    if ([GRID, HORIZONTAL].includes(stackMode)) {
      columnSize = 12;
    } else {
      columnSize = 2;
    }
  }

  const isHorizontalList = stackMode === HORIZONTAL;

  return (
    <div className="room-container">
      <JoinRequests />
      <header className="header-container">
        <Header />
      </header>
      <main className="middle-container">
        <PoorConnectionStrip />
        <Row
          className={clsx("participant-preview-container", {
            toggled: isParticipantSidebarOpen || isEventSidebarOpen
          })}
        >
          {mainParticipant && <MainParticipant />}
          {!isOwnScreenShare && (
            <Col
              xs={columnSize}
              className={clsx("participant-list", {
                hidden: selectedParticipant,
                horizontal: isHorizontalList
              })}
            >
              <SimpleBar
                className="simplebar-tiny"
                style={{
                  height: isHorizontalList
                    ? `${UI_ROOM.PARTICIPANT_LIST_HEIGHT(isLargeDevice)}px`
                    : `calc(${UI_WINDOW_HEIGHT} - ${UI_ROOM.ROOM_EXCESS_HEIGHT}px)`,
                  maxHeight: isHorizontalList
                    ? `${UI_ROOM.PARTICIPANT_LIST_HEIGHT(isLargeDevice)}px`
                    : `calc(${UI_WINDOW_HEIGHT} - ${UI_ROOM.ROOM_EXCESS_HEIGHT}px)`,
                  width: "100%",
                  maxWidth: "100%"
                }}
              >
                <ParticipantsList />
              </SimpleBar>
            </Col>
          )}

          <RecordingIndicator />
          {!isLargeDevice && (
            <div className="mobile-right-container">
              <Button
                className="circle-btn grey no-border"
                size="lg"
                onClick={handleInfoClicked}
              >
                <i className="ciq-icon ciq-ic-info" />
              </Button>
              <SettingsModalToggle />
            </div>
          )}
        </Row>
        <ParticipantSideBar />
        <EventSidebar />
      </main>
      <footer>
        <BottomActionBar />
      </footer>
    </div>
  );
}

export default TwilioRoom;
