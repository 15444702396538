import Pusher, { Channel, Member, Members } from "pusher-js";
import { AnalyticsService, LegacyMeetingService } from ".";

import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from "../constants/pusher";
import { PUSHER_KEEP_ALIVE_INTERVAL } from "../constants/timer";
import {
  BaseJoinRequest,
  CIQParticipant,
  JoinRequest
} from "../models/meeting";

class PusherService {
  pusherInstance?: Pusher.Pusher;

  activityTimeout?: any;

  channelName?: string;

  initializeInstance(
    email: string,
    meetingId: string,
    twilioToken: string,
    handleUserStateUpdate: () => void
  ): void {
    if (!this.pusherInstance) {
      this.pusherInstance = new Pusher(PUSHER_APP_KEY!, {
        cluster: PUSHER_APP_CLUSTER,
        forceTLS: true,
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        authorizer: () => ({
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          authorize: async (socketId, callback) => {
            try {
              const { auth, channelData } =
                await LegacyMeetingService.pusherAuthenticate({
                  socketId,
                  email,
                  meetingId,
                  twilioToken
                })
                  .toPromise()
                  .finally(() => {
                    handleUserStateUpdate();
                  });

              /* eslint-disable camelcase */
              callback(false, { auth, channel_data: channelData });
            } catch (err) {
              callback(true, undefined);
              AnalyticsService.pusherConnectError(err);
            }
          }
        }),
        encrypted: true
      });

      this.activityTimeout = setInterval(() => {
        this.keepAliveCheck();
      }, PUSHER_KEEP_ALIVE_INTERVAL);
    }
  }

  subscribeToChannel(
    channelName: string,
    email: string,
    meetingId: string,
    twilioToken: string,
    isGuestUser: boolean,
    handleUserStateUpdate: () => void,
    handleNewJoinRequest: (guestInviteUpdateData: JoinRequest) => void,
    handleJoinRequestUpdate: (guestInviteUpdateData: BaseJoinRequest) => void,
    onParticipantJoinedEvent: (participant: CIQParticipant) => void,
    onSubscriptionSucceededEvent: (participants: CIQParticipant[]) => void
  ): void {
    this.initializeInstance(
      email,
      meetingId,
      twilioToken,
      handleUserStateUpdate
    );
    this.channelName = channelName;
    const channel: Channel | undefined =
      this.pusherInstance?.subscribe(channelName);

    const configurePusherChannelBindings = (): void => {
      if (!isGuestUser) {
        channel?.bind(
          "new-event-guest",
          (guestInviteUpdateData: JoinRequest) => {
            handleNewJoinRequest(guestInviteUpdateData);
          }
        );

        channel?.bind(
          "update-event-guest",
          (guestInviteUpdateData: BaseJoinRequest) => {
            handleJoinRequestUpdate(guestInviteUpdateData);
          }
        );
      }

      channel?.bind("pusher:member_added", (member: Member<CIQParticipant>) => {
        onParticipantJoinedEvent(member.info);
      });
    };

    channel?.bind(
      "pusher:subscription_succeeded",
      (members: Members<CIQParticipant>) => {
        configurePusherChannelBindings();

        AnalyticsService.pusherConnectionSuccess();

        const onlineParticipants: CIQParticipant[] = [];

        members.each((member) => {
          onlineParticipants.push(member.info);
        });
        onSubscriptionSucceededEvent(onlineParticipants);
      }
    );
  }

  keepAliveCheck(): void {
    const {
      connection: { state }
    } = this.pusherInstance!;

    if (!["connected", "connecting"].includes(state)) {
      this.pusherInstance?.connect();
    }
  }

  unsubscribeFromChannel(): void {
    if (this.activityTimeout) clearInterval(this.activityTimeout);
    if (this.channelName) {
      this.pusherInstance?.unsubscribe(this.channelName);
    }
    this.pusherInstance = undefined;
    this.channelName = undefined;
  }
}

export default new PusherService();
