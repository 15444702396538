import React, { useEffect, useRef } from "react";
import { LocalVideoTrack, RemoteVideoTrack } from "twilio-video";
import { useMediaStreamTrack } from "../../hooks";

interface VideoPreviewProps {
  track: LocalVideoTrack | RemoteVideoTrack;
  isLocalParticipant: boolean;
}

const VideoPreview: React.FC<VideoPreviewProps> = ({
  track,
  isLocalParticipant
}): JSX.Element => {
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaStreamTrack(track);

  useEffect(() => {
    const el = ref.current;
    // To avoid browser's autoplay policy
    el.muted = true;

    track.attach(el);
    return (): void => {
      track.detach(el);
    };
  }, [track]);

  // Rotate front camera view by 180deg
  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== "environment";
  const videoStyle =
    isLocalParticipant && isFrontFacing ? { transform: "rotateY(180deg)" } : {};

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video ref={ref} className="video-preview" style={videoStyle} />;
};

export default VideoPreview;
