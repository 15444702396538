import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";

interface CustomLoaderProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  isFull?: boolean;
  isSidePanel?: boolean;
  loaderColor?: "light" | "dark" | "purple";
  isReconnect?: boolean;
  isConnecting?: boolean;
}

const CustomLoader: React.FC<CustomLoaderProps> = ({
  isFull,
  loaderColor,
  isSidePanel,
  isReconnect,
  isConnecting
}: CustomLoaderProps) => {
  const { t } = useTranslation("loader");
  return (
    <div
      className={clsx(`loader ${loaderColor}`, {
        "full-container": isFull,
        "side-container": isSidePanel
      })}
    >
      <Spinner size="md" color="secondary" />
      {isReconnect && <label>{t("reconnecting")}</label>}
      {isConnecting && <label>{t("connecting")}</label>}
    </div>
  );
};

CustomLoader.defaultProps = {
  isFull: false,
  isSidePanel: false,
  loaderColor: undefined,
  isReconnect: false,
  isConnecting: false
};

export default CustomLoader;
