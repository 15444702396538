const BASE_URL = process.env.NX_PUBLIC_BASE_URL;
const API_VERSION = process.env.NX_PUBLIC_API_VERSION;
export const API_URL = `${BASE_URL}/api/v${API_VERSION}`;

const MEETING_ENDPOINT = "/meetings";
const PUSHER_ENDPOINT = "/pusher";
const RECORDING_ENDPOINT = "/twilio-video";
const ZOOM_ENDPOINT = "/zoom-video";

export const MEETING_API = {
  GET_MEETING: (meetingId: string): string =>
    `${MEETING_ENDPOINT}/${meetingId}`,
  POST_USER_STATE: (meetingId: string): string =>
    `${MEETING_ENDPOINT}/${meetingId}/user-state`,
  GET_TIMEZONES: (): string => `${MEETING_ENDPOINT}/timezones`,
  POST_CLIENT_CRASH_DATA: (): string => `${MEETING_ENDPOINT}/client-crash`,
  GET_EXTERNAL_PARTICIPANTS: (meetingId: string): string =>
    `${MEETING_ENDPOINT}/${meetingId}/external-participants`,
  UPDATE_EXTERNAL_PARTICIPANT_STATE: (meetingId: string): string =>
    `${MEETING_ENDPOINT}/${meetingId}/external-participants`,
  DIAL_IN_PARTICIPANT: (meetingId: string): string =>
    `${MEETING_ENDPOINT}/${meetingId}/call-participant`,
  RECORD_MEETING: (): string => `${RECORDING_ENDPOINT}/recording-rules`,
  UPDATE_DIAL_IN_PARTICIPANT_STATUS: (meetingId: string): string =>
    `${MEETING_ENDPOINT}/${meetingId}/call-participant/status`
};

export const ZOOM_MEETING_API = {
  CHECK_IF_USES_ZOOM: (meetingId: string) =>
    `${ZOOM_ENDPOINT}/meeting-use-zoom-connection/${meetingId}`,
  GET_CONFIGURATION: (meetingId: string) =>
    `${ZOOM_ENDPOINT}/zoom-meeting-configuration/${meetingId}`
};

export const PUSHER_API = {
  PUSHER_AUTHENTICATE: (): string => `${PUSHER_ENDPOINT}/authenticate`
};

// Error Codes
export const REST_ERROR = {
  REQUEST_CANCELED: "REQUEST_CANCELED",
  NETWORK_ERROR: "NETWORK_ERROR",
  INTERNAL_ERROR: "INTERNAL_ERROR"
};
